<template>
    <div>

        <!--Modals-->
        <span>

            <!--Add Document Modal-->
            <FileUpload :bool1="dm" :bool2="vdm" :bool3="addm" :bool4="ledm"
                        :method1="postPutDocument" :method2="postPutVehicleDocument" :method3="postPutAddressDocument" :method4="postPutLeReportDocument"
                        v-model="profileObjects.documentDescription"/>

            <!--View Files-->
            <FileViewer :fileId="fileViewStorageId"/>

            <!--File Selector-->
            <FileSelector :files="fileSelectorFiles" :method1="setFileFavorite" :bool1="docSelectBool"/>

            <!--Edit Document Modal-->
            <b-modal id="edit-doc" no-close-on-backdrop scrollable title="Edit Document/Media" class="modal-dark">

                <b-input-group prepend="Description" class="input-group-sm" style="margin-bottom: 1px">
                    <b-form-input v-model="profileObjects.documents.data.description"/>
                </b-input-group>

                <!--Cancel and Submit Buttons-->
                <template slot="modal-footer">
                    <b-button size="sm" variant="secondary" @click="$bvModal.hide('edit-doc')">Cancel</b-button>
                    <b-button size="sm" variant="primary" @click="changeDocumentDescription()">Submit</b-button>
                </template>

            </b-modal>

            <!--Edit Vehicle Document Modal-->
            <b-modal id="edit-vdoc" no-close-on-backdrop scrollable title="Edit Vehicle Document/Media" class="modal-dark">

                <b-input-group prepend="Description" class="input-group-sm" style="margin-bottom: 1px">
                    <b-form-input v-model="profileObjects.vehicles.data.description"/>
                </b-input-group>

                <!--Cancel and Submit Buttons-->
                <template slot="modal-footer">
                    <b-button size="sm" variant="secondary" @click="$bvModal.hide('edit-vdoc')">Cancel</b-button>
                    <b-button size="sm" variant="primary" @click="changeVDDescription()">Submit</b-button>
                </template>

            </b-modal>

            <!--Edit Address Document Modal-->
            <b-modal class="modal-dark" id="edit-addoc" no-close-on-backdrop scrollable title="Edit Vehicle Document/Media">

                <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                    <b-form-input v-model="profileObjects.addresses.data.description"/>
                </b-input-group>

                <!--Cancel and Submit Buttons-->
                <template slot="modal-footer">
                    <b-button size="sm" variant="secondary" @click="$bvModal.hide('edit-addoc')">Cancel</b-button>
                    <b-button size="sm" variant="primary" @click="changeAddressDDescription()">Submit</b-button>
                </template>

            </b-modal>

            <!--Edit LE Report Document Modal-->
            <b-modal id="edit-ledoc" no-close-on-backdrop scrollable title="Edit LE-Report Document/Media" class="modal-dark">

                <b-input-group prepend="Description" class="input-group-sm" style="margin-bottom: 1px">
                    <b-form-input v-model="profileObjects.leReports.data.description"/>
                </b-input-group>

                <!--Cancel and Submit Buttons-->
                <template slot="modal-footer">
                    <b-button size="sm" variant="secondary" @click="$bvModal.hide('edit-ledoc')">Cancel</b-button>
                    <b-button size="sm" variant="primary" @click="changeLEDDescription()">Submit</b-button>
                </template>

            </b-modal>

            <!--Edit Note-->
            <b-modal id="edit-note" no-close-on-backdrop scrollable title="Edit Note" class="modal-dark">
                <b-row>
                    <b-col sm="12">
                        <b-form-group>
                            <b-form-textarea v-model="noteText"></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                <template slot="modal-footer">
                    <b-button @click="$bvModal.hide('edit-note')" size="sm" variant="secondary">Cancel</b-button>
                    <b-button @click="editNote()" size="sm" variant="primary">Submit</b-button>
                </template>
            </b-modal>

            <!--Update Case Profiles-->
            <b-modal no-close-on-backdrop scrollable title="Add Profiles To Case" class="modal-dark"
                     v-model="profiles.modal"
                     size="lg">
                <v-client-table ref="dTable" style="width: 100%" :columns="profiles.columns" :data="profiles.all"
                                :options="profiles.tOptions" :theme="profiles.theme" class="dataTable">
                    <b-form-checkbox slot-scope="props" slot="check" v-model="profiles.selected"
                                     :value="props.row.profileId.toString()"></b-form-checkbox>
                    <div slot="h__check">
                        <b-form-checkbox type="checkbox" style="padding-left: 24px" class="check-all"
                                         v-model='profiles.allMarked'
                                         @change="profiles.selected = unmarkAndMarkAll(profiles.selected, profiles.allMarked)"></b-form-checkbox>
                    </div>
                    <span slot="name" slot-scope="props">{{nameify(props.row)}}</span>
                    <span slot="DOB"
                          slot-scope="props">{{dateify(props.row.birthMonth, props.birthDay, props.birthYear)}}</span>
                    <span slot="alias" slot-scope="props">{{aliasify(props.row.alias)}}</span>
                </v-client-table>
                <template slot="modal-footer">
                    <b-button size="sm" variant="secondary" @click="profiles.modal = false">Cancel</b-button>
                    <b-button size="sm" variant="primary" @click="updateProfiles(editCase.data)">Submit</b-button>
                </template>
            </b-modal>

            <!--Edit/Add Notes-->
            <b-modal class="modal-dark" scrollable title="Notes" v-model="profileObjects.notes.modal">
                <b-row>
                    <b-col sm="12">
                        <b-form-group>
                            <b-form-textarea v-model="profileObjects.notes.newNote"></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                <template slot="modal-footer">
                    <b-button @click="profileObjects.notes.modal = false" size="sm" variant="secondary">Cancel</b-button>
                    <b-button @click="putNote()" size="sm" variant="primary">Submit</b-button>
                </template>
            </b-modal>

            <!--Edit/Add Addresses-->
            <b-modal scrollable title="Add Address" class="modal-dark" v-model="profileObjects.addresses.postPut"
                     :size="!profileObjects.addresses.add ? 'lg' : 'md'" v-if="profileObjects.addresses.postPut" no-body>
                <span v-if="!profileObjects.addresses.add">
                    <b-form-radio-group v-model="profileObjects.addresses.addAddressId">
                        <v-client-table style="width: 100%" class="dataTable" :columns="profileObjects.addresses.table.columns"
                                        :data="profileObjects.addresses.allAddresses ? profileObjects.addresses.allAddresses : []"
                                        :options="profileObjects.addresses.table.tOptions"
                                        :theme="profileObjects.addresses.table.theme" ref="dTable">
                            <b-form-radio slot="Select" slot-scope="props" :value="props.row.addressId"/>
                        </v-client-table>
                    </b-form-radio-group>
                </span>
                <span v-else>
                    <b-input-group class="input-group-sm" prepend="Address" style="margin-bottom: 1px">
                        <b-form-input ref="autocomplete"
                                      id="autocomplete"
                                      placeholder="Enter Address"
                                      type="text"
                                      v-model="addressInput"
                                      types=""
                                      autocomplete="on"
                                      v-uppercase />
                        <b-dropdown :text="profileObjects.addresses.data.active ? 'Active' : 'Inactive'"
                                    size="sm" variant="dark">
                            <b-dropdown-item @click="profileObjects.addresses.data.active = true">Active
                            </b-dropdown-item>
                            <b-dropdown-item @click="profileObjects.addresses.data.active = false">Inactive
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-input-group>
                    <b-input-group class="input-group-sm" prepend="Apartment/Unit" style="margin-bottom: 1px">
                        <b-form-input v-model="profileObjects.addresses.data.address2" v-uppercase/>
                    </b-input-group>
                    <b-input-group prepend="City" class="input-group-sm">
                        <b-form-input v-model="profileObjects.addresses.data.city" v-uppercase/>
                        <b-input-group-append class="input-group-sm">
                            <b-input-group-text>State</b-input-group-text>
                        </b-input-group-append>
                        <b-form-select size="sm" :options="states" v-model="profileObjects.addresses.data.state"/>
                        <b-input-group-append class="input-group-sm">
                            <b-input-group-text>Zip</b-input-group-text>
                        </b-input-group-append>
                        <b-form-input type="number" v-model.number="profileObjects.addresses.data.zip"
                                      :class=" 'form-control is-' + (filledOut(profileObjects.addresses.data.zip) ? 'valid':'invalid')"/>
                    </b-input-group>
                    <b-form-group><br>
                        <label>Other Notes</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;"
                                 @click="push(profileObjects.addresses.data.notes, {text: '', scope: intelCaseId})"/>
                        <div v-if="profileObjects.addresses.data.notes && refresh" :key="i"

                             v-for="(note,i) in refactorScope(profileObjects.addresses.data.notes)">
                            <b-input-group v-if="!note.scope || note.scope === intelCaseId || note.scope === 'global'">

                                <div class="col" style="padding: 0; width: 55px">
                                    <b-form-input v-model="profileObjects.addresses.data.notes[i].text" size="sm"/>
                                </div>
                                <div class="col-auto" style="padding: 0 4px 0 0;">
                                    <b-form-select v-model ="profileObjects.addresses.data.notes[i].scope"
                                                   size="sm" plain
                                                   style="background-color: #191919; border-color: #191919; ">
                                        <option :value="intelCaseId">Scoped</option>
                                        <option :value="'global'">Global</option>
                                    </b-form-select>
                                </div>
                                <b-input-group-append v-if="profileObjects.addresses.data.notes.length > 1">
                                    <b-button variant="dark" @click="splice(i, profileObjects.addresses.data.notes)" size="sm">
                                        <fa-icon :icon="['fas', 'close']"/>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                    </b-form-group>
                </span>
                <template slot="modal-footer">
                    <b-button size="sm" variant="secondary" @click="profileObjects.addresses.postPut = false">Cancel</b-button>
                    <b-button @click="profileObjects.addresses.add = !profileObjects.addresses.add" size="sm" variant="success"
                              v-if="!profileObjects.addresses.data.addressId">
                    {{profileObjects.addresses.add ? "Add Existing" : "Create New"}}
                    </b-button>
                    <b-button v-if="profileObjects.addresses.postPut"
                              :disabled="(!filledOut(profileObjects.addresses.data.zip) && profileObjects.addresses.add) || (!profileObjects.addresses.addAddressId && !profileObjects.addresses.add)"
                              @click="postPutAddress()" size="sm" variant="primary">Submit</b-button>
                </template>
            </b-modal>

            <!--Edit/Add Vehicle-->
            <b-modal scrollable title="Add Vehicle" class="modal-dark" v-model="profileObjects.vehicles.postPut">
                <b-input-group class="input-group-sm d-flex no-wrap" prepend="Make" style="margin-bottom: 1px">
                    <formSelectSearch :upperCase="true" :options="allMakes" v-model="vehicleMake" :merge-left="true"
                                      :arrow="false" size="sm" v-if="allMakes && allMakes.length > 0" class="flex-grow-1"/>
                    <p v-else style="width: 184px; margin: 0; padding-top: 3px; text-align: center;">Loading</p>
                </b-input-group>
                <b-input-group class="input-group-sm d-flex no-wrap" prepend="Model" style="margin-bottom: 2px">
                    <formSelectSearch :upperCase="true" :options="allModels" v-model="profileObjects.vehicles.data.model"
                                      :merge-left="true" :arrow="false" size="sm" v-if="vehicleMake" class="flex-grow-1"/>
                    <b-input-group-append v-else class="input-group-sm flex-grow-1 d-flex">
                        <b-input-group-text style="background-color: #333333" class="d-flex flex-grow-1">
                            <span class="flex-grow-1 align-content-center">Select a make first</span>
                        </b-input-group-text>
                    </b-input-group-append>
                </b-input-group>
                <b-input-group class="input-group-sm" prepend="Color" style="margin-bottom: 1px">
                    <b-form-input v-model="profileObjects.vehicles.data.color" v-uppercase/>
                    <b-input-group-append class="input-group-sm">
                        <b-input-group-text>Year</b-input-group-text>
                    </b-input-group-append>
                    <b-form-select :options="arrayify(1900,(new Date()).getFullYear() + 1, false)"
                                   type="number"
                                   v-model.number="profileObjects.vehicles.data.year"/>
                </b-input-group>
                <b-input-group class="input-group-sm" prepend="State" style="margin-bottom: 1px">
                    <b-form-select :options="states" v-model="profileObjects.vehicles.data.state" style=""/>
                    <b-input-group-append class="input-group-sm">
                        <b-input-group-text>Plate</b-input-group-text>
                    </b-input-group-append>
                    <b-form-input v-model="profileObjects.vehicles.data.plate" v-uppercase/>
                </b-input-group>
                <b-input-group class="input-group-sm" prepend="VIN" style="margin-bottom: 1px">
                    <b-form-input v-model="vin" v-uppercase/>
                </b-input-group>
                <div style="text-align: center;">
                    <b-button :disabled="vin === ''" @click="encodeVehicleVIN()" style="margin: 3px;">Decode Information with VIN</b-button>
                    <p style="font-size: 10px; margin: 3px;">Press button to autofill vehicle fields based on the VIN number</p>
                </div>
                <b-input-group class="input-group-sm" prepend="Registered Owner" style="margin-bottom: 1px">
                    <b-form-input v-model="profileObjects.vehicles.data.registeredOwner" v-uppercase/>
                </b-input-group>
                <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                    <textarea class="form-control rounded-0" rows="3" v-model="profileObjects.vehicles.data.description" type="text"></textarea>
                </b-input-group>
                <br>
                <b-form-group>
                    <label>Other Notes</label>&nbsp;
                    <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;"
                             @click="push(profileObjects.vehicles.data.notes, {text: '', scope: intelCaseId})"/>
                    <div v-if="profileObjects.vehicles.data.notes && refresh" :key="i"
                         v-for="(e,i) in refactorScope(profileObjects.vehicles.data.notes)">
                        <b-input-group v-if="!e.scope || e.scope === intelCaseId" class="input-group-sm">
                            <textarea class="form-control rounded-0" rows="4" v-model="profileObjects.vehicles.data.notes[i].text" type="text"></textarea>
                            <b-input-group-append v-if="profileObjects.vehicles.data.notes.length > 1">
                                <b-button variant="dark" @click="splice(i, profileObjects.vehicles.data.notes)">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </b-form-group>
                <template slot="modal-footer">
                    <b-button size="sm" variant="secondary" @click="profileObjects.vehicles.postPut = false">Cancel</b-button>
                    <b-button v-if="profileObjects.vehicles.postPut" :disabled="oneFilledOut(profileObjects.vehicles.data,profileObjects.vehicles.fields)" @click="postPutVehicle()" size="sm" variant="primary">Submit</b-button>
                </template>
            </b-modal>

            <!--Edit/Add LE-Report-->
            <b-modal class="modal-dark" scrollable title="LE-Report" v-model="profileObjects.leReports.postPut"
                     :size="!profileObjects.leReports.add ? 'lg' : 'md'" v-if="profileObjects.leReports.postPut" no-body>
                <span v-if="!profileObjects.leReports.add">
                    <b-form-radio-group v-model="profileObjects.leReports.addId">
                        <v-client-table style="width: 100%" class="dataTable" :columns="profileObjects.leReports.table.columns"
                                        :data="profileObjects.leReports.all ? profileObjects.leReports.all : []"
                                        :options="profileObjects.leReports.table.tOptions" :theme="profileObjects.leReports.table.theme" ref="dTable">
                            <b-form-radio slot="Select" slot-scope="props" :value="props.row.leReportId"/>
                            <span slot="createdDate" slot-scope="props">{{momentDate(props.row.createdDate)}}</span>
                        </v-client-table>
                    </b-form-radio-group>
                </span>
                <span v-else>
                    <b-input-group class="input-group-sm" prepend="Subject" style="margin-bottom: 1px;">
                        <b-form-input
                                :class=" 'form-control is-' + (filledOut(profileObjects.leReports.data.subject) ? 'valid':'invalid')"
                                v-model="profileObjects.leReports.data.subject"/>
                    </b-input-group>
                    <b-input-group class="input-group-sm" prepend="Report Number" style="margin-bottom: 1px">
                        <b-form-input
                                :class=" 'form-control is-' + (filledOut(profileObjects.leReports.data.leReportNumber) ? 'valid':'invalid')"
                                v-model="profileObjects.leReports.data.leReportNumber"/>
                    </b-input-group>
                    <b-input-group class="input-group-sm" prepend="Involvement" style="margin-bottom: 1px">
                        <b-form-select
                                :class=" 'form-control is-' + (filledOut(profileObjects.leReports.data.involvement) ? 'valid':'invalid')"
                                v-model="profileObjects.leReports.data.involvement"
                                :options="['ARRESTEE', 'WITNESS', 'SUSPECT', 'VICTIM', 'OTHER']"/>
                    </b-input-group>
                    <b-input-group class="input-group-sm" prepend="Date" style="margin-bottom: 1px">
                        <date-picker :config="dateOptions" v-model="profileObjects.leReports.data.date"/>
                    </b-input-group>
                    <b-input-group class="input-group-sm" prepend="Description" style="margin-bottom: 1px">
                        <textarea maxlength="500"
                                  :class=" 'form-control is-' + (filledOut(profileObjects.leReports.data.description) ? 'valid':'invalid')"
                                  class="form-control rounded-0" rows="4" v-model="profileObjects.leReports.data.description" type="text"></textarea>
                    </b-input-group>
                    <br>
                    <b-form-group>
                        <label>Other Notes</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;"
                                 @click="push(profileObjects.leReports.data.notes, {text: '', scope: intelCaseId})"/>
                        <div v-if="profileObjects.leReports.data.notes && refresh" :key="i"
                             v-for="(note,i) in refactorScope(profileObjects.leReports.data.notes)">
                            <b-input-group v-if="!note.scope || note.scope === intelCaseId || note.scope === 'global'" class="input-group-sm">

                                <div class="col" style="padding: 0; width: 55px">
                                    <b-form-input v-model="profileObjects.leReports.data.notes[i].text" size="sm"/>
                                </div>

                                <div class="col-auto" style="padding: 0 4px 0 0;">
                                    <b-form-select v-model ="profileObjects.addresses.data.notes[i].scope"
                                                   size="sm" plain
                                                   style="background-color: #191919; border-color: #191919; ">
                                        <option :value="intelCaseId">Scoped</option>
                                        <option :value="'global'">Global</option>
                                    </b-form-select>
                                </div>
                                <b-input-group-append v-if="profileObjects.leReports.data.notes.length > 1">
                                    <b-button @click="splice(i, profileObjects.leReports.data.notes)" variant="dark" size="sm">
                                        <fa-icon :icon="['fas', 'close']"/>
                                    </b-button>
                                </b-input-group-append>
                             </b-input-group>
                        </div>
                    </b-form-group>
                </span>
                <template slot="modal-footer">
                    <b-button @click="profileObjects.leReports.postPut = false" size="sm" variant="secondary">
                        Cancel
                    </b-button>
                    <b-button @click="profileObjects.leReports.add = !profileObjects.leReports.add" size="sm" variant="success"
                              v-if="!profileObjects.leReports.data.leReportId">
                        {{profileObjects.leReports.add ? "Add Existing" : "Create New"}}
                    </b-button>
                    <b-button :disabled="(disableLeReportSubmit() && profileObjects.leReports.add) || (!profileObjects.leReports.addId && !profileObjects.leReports.add)"
                              @click="postPutLeReports()" size="sm" variant="primary">
                        Submit
                    </b-button>
                </template>
            </b-modal>

            <!--Delete Object-->
            <b-modal scrollable title="Remove Item" class="modal-dark" v-model="deleteObjects.modal">
                <p>If you would like to delete this {{deleteObjects.name}} please type the prompt below.</p>
                <br>
                <p style="margin-bottom: 0">Enter "{{deleteObjects.name}}":</p>
                <b-form-group description="case sensitive">
                    <b-form-input v-model="deleteObjects.deleteText" id="basicName2" type="text"></b-form-input>
                </b-form-group>
                <template slot="modal-footer">
                    <b-button size="sm" variant="secondary" @click="deleteObjects.modal = false">Cancel</b-button>
                    <b-button @click="deleteObjectsMethod()" size="sm" variant="danger"
                              :disabled="deleteObjects.deleteText !== deleteObjects.name">Delete {{deleteObjects.name}}
                    </b-button>
                </template>
            </b-modal>

            <!--Delete Vehicle Document-->
            <b-modal scrollable title="Remove Item" class="modal-dark" v-model="deleteVD.modal">
                <p>If you would like to delete this {{deleteVD.name}} please type the prompt below.</p>
                <br>
                <p style="margin-bottom: 0">Enter "{{deleteVD.name}}":</p>
                <b-form-group description="case sensitive">
                    <b-form-input v-model="deleteVD.deleteText" id="basicName2" type="text"></b-form-input>
                </b-form-group>
                <template slot="modal-footer">
                    <b-button size="sm" variant="secondary" @click="deleteVD.modal = false">Cancel</b-button>
                    <b-button @click="deleteVDMethod()" size="sm" variant="danger"
                              :disabled="deleteVD.deleteText !== deleteVD.name">Delete {{deleteVD.name}}
                    </b-button>
                </template>
            </b-modal>

            <!--Edit Intel Case-->
            <b-modal no-close-on-backdrop scrollable title="Edit Intel Case" class="modal-dark" v-model="editCase.modal"
                     size="lg">
                <b-row>

                    <!--Case Name-->
                    <b-col sm="8">
                        <b-form-group>
                            <label>Case Name</label>
                            <b-form-input v-model="editCase.data.caseName" v-uppercase/>
                        </b-form-group>
                    </b-col>

                    <!--Status-->
                    <b-col sm="6">
                        <b-form-group>
                            <label>Status</label>
                            <b-form-select :options="['OPEN', 'CLOSED', 'ON HOLD']" v-model="editCase.data.status"/>
                        </b-form-group>
                    </b-col>

                    <!--Description-->
                    <b-col sm="12">
                        <b-form-group>
                            <label>Description</label>
                            <b-form-textarea maxlength="2000" v-model="editCase.data.description"/>
                        </b-form-group>
                    </b-col>

                    <!--Addresses-->
                    <!--21<b-col sm="12">
                        <label>{{fields.addresses.name}}</label>&nbsp;<i @click="push(editCase.data.addresses, fields.addresses.format)" class="fa fa-plus-circle fa-md"/>
                        <b-list-group v-for="(e,i) in editCase.data.addresses" :key="i"  v-if="refresh">
                            <b-list-group-item>
                                <strong>Addresses #{{i+1}}</strong>
                                <i style="float:right" class="fa fa-close fa-sm" @click="splice(i, editCase.data.addresses)" v-if="editCase.data.addresses && editCase.data.addresses.length > 1"/>
                                <b-input-group class="input-group-sm" prepend="Address Line 1" style="margin-bottom: 1px">
                                    <b-form-input v-model="editCase.data.addresses[i].address"/>
                                </b-input-group>
                                <b-input-group class="input-group-sm" prepend="Address Line 2" style="margin-bottom: 1px">
                                    <b-form-input v-model="editCase.data.addresses[i].address2"/>
                                </b-input-group>
                                <b-input-group prepend="City" class="input-group-sm">
                                    <b-form-input v-model="editCase.data.addresses[i].city"/>
                                    <b-input-group-append class="input-group-sm"><b-input-group-text>State</b-input-group-text></b-input-group-append>
                                    <b-form-select size="sm" :options="states" v-model="editCase.data.addresses[i].state"/>
                                    <b-input-group-append class="input-group-sm"><b-input-group-text>Zip</b-input-group-text></b-input-group-append>
                                    <b-form-input type="number" v-model="editCase.data.addresses[i].zip"/>
                                </b-input-group>
                                <b-input-group prepend="Notes" class="input-group-sm">
                                    <b-form-input size="sm" v-model="editCase.data.addresses[i].notes"/>
                                </b-input-group>
                            </b-list-group-item>
                        </b-list-group><br>
                    </b-col>-->

                    <!--Profiles Table-->
                    <b-col sm="12">
                        <!--<v-client-table ref="dTable" style="width: 100%" :columns="profiles.columns" :data="profiles.data" :options="profiles.tOptions" :theme="profiles.theme" class="dataTable">
                            <b-form-checkbox slot-scope="props" slot="check" v-model="profiles.select.selected" :value="props.row.id"></b-form-checkbox>
                            <div slot="h__check">
                              <b-form-checkbox type="checkbox" style="padding-left: 24px" class="check-all" v-model='profiles.select.allMarked' @change="profiles.select.selected = unmarkAndMarkAll(profiles.select.selected, profiles.select.allMarked)"></b-form-checkbox>
                            </div>
                        </v-client-table>-->
                    </b-col>

                </b-row>
                <template slot="modal-footer">
                    <b-button size="sm" variant="secondary" @click="close()">Cancel</b-button>
                    <b-button size="sm" variant="primary" @click="putCase(editCase.data)">Submit</b-button>
                </template>
            </b-modal>

            <!-- Are You Sure Delete -->
            <b-modal no-close-on-backdrop scrollable ok-title="Submit" title="Delete Intel Case" class="modal-dark"
                     id="deleteIntelCase">
                <p>If you would like to permanently remove this organization please type the name Below.</p>
                <br>
                <p>Enter "{{curIntelCase.caseName}}":</p>
                <b-form-group description="case sensitive">
                    <b-form-input v-model="intelCaseNameVerification" id="basicName3" type="text"></b-form-input>
                </b-form-group>
                <template slot="modal-footer">
                    <b-button size="sm" variant="secondary" @click="$bvModal.hide('deleteIntelCase')">Cancel</b-button>
                    <b-button size="sm" :disabled="intelCaseNameVerification !== curIntelCase.caseName"
                               variant="danger" @click="deleteIntelCase">
                        <fa-icon :icon="['fas', 'trash-can']" size="lg"/>Submit
                    </b-button>
                </template>
            </b-modal>

        </span>

        <!--Main Page-->
        <b-card>

            <!--Header-->
            <div slot="header">
                <span class="d-flex w-100 justify-content-between justify-content-center">
                    <h5 style="margin-bottom: 0">
                        <b>{{curIntelCase.caseNumber}}: {{curIntelCase.caseName}}</b>&nbsp;
                        <span style="transform: translateY(-2px)" :class="'badge badge-' + statusBadge(curIntelCase.status)">{{curIntelCase.status}}</span>
                    </h5>
                    <span>
                        <fa-icon :icon="['fas', 'pen-to-square']" @click="openEditModal()" style="padding-right: 10px; cursor: pointer;"/>
                        <fa-icon :icon="['fas', 'trash-can']" @click="$bvModal.show('deleteIntelCase')" style="padding-right: 10px; cursor: pointer;"/>
                    </span>
                </span>
            </div>

            <!--Body-->
            <b-row>

                <!--Tabs/Profiles/Map-->
                <b-col sm="9" class="d-flex flex-column" style="padding: 0; flex-wrap: wrap">

                    <!--Tabs/Map-->
                    <span class="container-fluid" style="padding: 0">
                        <b-row style="width: 100%; margin: 0;">

                            <!--Tabs-->
                            <b-col xl="8" style="padding: 0">
                                <b-card bg-variant="dark" no-body style="border-color: #000001; background-color: #0e0e0e !important; margin: 5px; height: 530px" fluid>
                                    <b-tabs variant="dark" class="d-flex flex-column" style="height: 530px" v-model="tabs">

                                        <!--Address Tab-->
                                        <b-tab title="Addresses" class="tab-scroll active" style="background-color: #1a1a1a">
                                            <div style="overflow-y: auto">
                                                <b-row>
                                                    <b-col :key="index" lg="12" v-for="(address, index) in curIntelCase.addresses">
                                                        <b-card no-body style="border-color: #0e0e0e;background-color: #202020">
                                                            <span class="d-flex w-100 justify-content-between" style="background-color: #0e0e0e; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                                                                <span>
                                                                    <img style="height: 21px" src="http://maps.google.com/mapfiles/ms/icons/blue-dot.png" alt=""/>
                                                                    <b style="font-size: medium">{{ curIntelCase.caseName }}&nbsp;</b>
                                                                </span>
                                                                <span>
                                                                    <fa-icon :icon="['fas', 'pen-to-square']" style="cursor: pointer;" @click="openModalEditAddresses(address)"/>&nbsp;
                                                                    <fa-icon :icon="['fas', 'close']" style="cursor: pointer;" @click="openModalDeleteObject('Address', address.addressId)"/>
                                                                </span>
                                                            </span>
                                                            <b-row style="margin: 0; padding: 0">
                                                                <b-col class="d-flex flex-column" style="margin: 0; padding: 0"
                                                                       :xl="address.documents && address.documents.length > 0 ? 9 : 12">
                                                                    <div style="padding: 5px 0 5px 0; height: 100%">
                                                                        <div class="p-0 d-flex flex-column align-items-center justify-content-center" style="border-bottom: #282d32 1px; border: none; height: 100%">
                                                                            <strong v-if="address.address1 !== ''" style="font-size: 15px">{{address.address1}}<br></strong>
                                                                            <strong v-if="address.address2 !== ''" style="font-size: 15px">{{address.address2}}<br></strong>
                                                                            <strong style="text-align: center; font-size: 15px" v-if="address.city !== ''">
                                                                                {{address.city + ", " + address.state + " " + address.zip}}
                                                                            </strong>
                                                                            <strong style="text-align: center; font-size: 15px; position: relative" v-else>
                                                                                {{address.state + " " + address.zip}}
                                                                            </strong>
                                                                            <strong class="text-muted text-uppercase font-weight-bold font-xs d-flex flex-row justify-content-end align-items-end" style="position: absolute;width: 100%; height: 100%; text-align: end; padding: 1px">
                                                                                Created By: {{address.createdBy}}
                                                                            </strong>
                                                                        </div>
                                                                    </div>
                                                                </b-col>
                                                                <b-col style="margin: 0; padding: 0" xl="3"
                                                                       class="d-flex justify-content-center"
                                                                       v-if="address.documents && address.documents.length > 0">
                                                                    <span style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px;"
                                                                          class="image-parent">
                                                                        <img class="img-center-ass" style="border-radius: 5px;"
                                                                             v-if="address.documents && address.documents.length > 0"
                                                                             v-auth-image="getFirstDocumentImage(address.documents)"
                                                                             @click="openViewModal(address.documents[0].storageId)"
                                                                             alt=""/>
                                                                        <img v-else class="img-center-ass"
                                                                             style="border-radius: 5px;"
                                                                             src="/NoImageAvailable.png"
                                                                             alt="">
                                                                    </span>
                                                                </b-col>
                                                            </b-row>
                                                            <div v-if="profileObjects.addresses.noteExpand[index]" style="border-radius: 3px">
                                                                <br>
                                                                <div style="padding: 10px">
                                                                    <h4 style="margin-bottom: 5px;"><strong>Notes </strong></h4>
                                                                    <div v-if="verifyThereAreNoNotes(address.notes.filter(_ => !_.scope || _.scope === intelCaseId || _.scope === 'global'))" style="width: 100%; display: flex; align-items: center; padding-left: 10px">
                                                                        <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313">
                                                                            <span style="text-align: center; width: 100%">no notes to display</span>
                                                                        </b-card>
                                                                    </div>
                                                                    <div v-else>
                                                                        <div :key="i" v-for="(note, i) in address.notes.filter(_ => !_.scope || _.scope === intelCaseId || _.scope === 'global')" style="padding-left: 10px">
                                                                            <b-card no-body style="margin: 0 0 5px 0; width: 100%; padding: 3px; background-color: #131313" class="d-flex flex-row justify-content-between flex-wrap">
                                                                                <div v-if="note.text !== ''" style="width: fit-content; word-break: break-word;"><b>{{(i+1) + ") "}}</b>{{note.text}}</div>
                                                                                <div class="text-muted text-uppercase font-weight-bold font-xs flex-grow-1 text-right"
                                                                                     style="width: fit-content">Created By: {{note.createdBy}}
                                                                                </div>
                                                                            </b-card>
                                                                        </div>
                                                                    </div>
                                                                    <br>
                                                                    <div class="d-flex flex-wrap-reverse">
                                                                        <div class="d-flex flex-row align-items-center">
                                                                            <h4 style="margin-bottom: 5px"><strong>Documents/Media</strong></h4>&nbsp;
                                                                            <b-badge variant="success" style="height: fit-content; margin-bottom: 5px" @click="openModalAddAddressDocuments(index)">
                                                                                <fa-icon :icon="['fas', 'plus']"/>Add
                                                                            </b-badge>
                                                                        </div>
                                                                        <fa-icon v-if="address.documents && address.documents.length > 1" :icon="['fas', 'right-left']"
                                                                           @click="openModalFileSelector(address.documents, 'addresses', index)"
                                                                           class="d-flex justify-content-end"
                                                                           style="flex-grow: 1; margin: 3px; cursor: pointer;"/>
                                                                    </div>
                                                                    <div style="padding-left: 10px">
                                                                        <v-client-table :columns="dTable.columns" :data="address.documents ? address.documents : []" :options="dTable.tOptions" :theme="dTable.theme">
                                                                            <div slot="file" slot-scope="props">
                                                                                <img v-auth-image="getImage(props.row.storageId)" style="max-height: 50px;"
                                                                                     @click="openViewModal(props.row.storageId)"
                                                                                     alt=""/>
                                                                            </div>
                                                                            <span slot="h__edit_delete" style="float: right">Edit/Delete</span>
                                                                            <div slot="edit_delete" slot-scope="props" style="float: right">
                                                                                <fa-icon :icon="['fas', 'pen-to-square']" style="cursor: pointer;"
                                                                                         @click="openModalEditAddressDocuments(props.row, index, props.row.documentId)"/>&nbsp;
                                                                                <fa-icon :icon="['fas', 'close']" style="cursor: pointer;"
                                                                                         @click="openModalDeleteVD('Address Document', props.row.documentId, index)"/>
                                                                            </div>
                                                                        </v-client-table>
                                                                    </div>
                                                                </div>
                                                                <div style="text-align: center; margin: 0; cursor: pointer;"
                                                                     @click="profileObjects.addresses.noteExpand.splice(index, 1, false)">
                                                                    <fa-icon :icon="['fas', 'angle-up']" style="text-align: center"/>
                                                                </div>
                                                            </div>
                                                            <div v-else style="text-align: center; margin: 0; border-radius: 3px; z-index: 1; cursor: pointer;"
                                                                 @click="profileObjects.addresses.noteExpand.splice(index, 1, true)">
                                                                <fa-icon :icon="['fas', 'angle-down']"/>
                                                            </div>
                                                        </b-card>
                                                    </b-col>
                                                </b-row>
                                                <p style="text-align: center; cursor: pointer;" @click="openModalAddAddress()">
                                                    <fa-icon :icon="['fas', 'plus']"/> Add Address
                                                </p>
                                            </div>
                                        </b-tab>

                                        <!--Documents/Media-->
                                        <b-tab title="Documents/Media" class="tab-scroll">
                                            <v-client-table style="width: 100%" :columns="dTable.columns" :data="curIntelCase.documents" :options="dTable.tOptions" :theme="dTable.theme">
                                                <div slot="file" slot-scope="props">
                                                    <img v-auth-image="getImage(props.row.storageId)" style="max-height: 50px;"
                                                         @click="openViewModal(props.row.storageId)" alt=""/>
                                                </div>
                                                <div slot="edit_delete" slot-scope="props">
                                                    <fa-icon :icon="['fas', 'pen-to-square']" style="cursor: pointer;" @click="openModalEditDocuments(props.row, props.row.documentId)"/>&nbsp;
                                                    <fa-icon :icon="['fas', 'close']" style="cursor: pointer;" @click="openModalDeleteObject('Document', props.row.documentId)"/>
                                                </div>
                                            </v-client-table>
                                            <div style="text-align: center">
                                                <span @click="openModalAddDocuments()">
                                                    <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;"/>
                                                    &nbsp;new document/media
                                                </span>
                                            </div>
                                        </b-tab>

                                        <!--Vehicles-->
                                        <b-tab title="Vehicles" class="tab-scroll">
                                            <b-col style="padding: 0">
                                                <span v-for="(vehicle, index) in curIntelCase.vehicles" :key="index">
                                                    <b-card style="border-color: #0e0e0e;background-color: #202020;" no-body>
                                                        <span class="d-flex w-100 justify-content-between" style="background-color: #0e0e0e; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                                                            <span>
                                                                <b style="font-size: medium">{{vehicle.year}} {{vehicle.make}} {{vehicle.model}}</b>
                                                            </span>
                                                            <span>
                                                                <fa-icon :icon="['fas', 'pen-to-square']" style="cursor: pointer;" @click="openModalEditVehicles(vehicle)"/>&nbsp;
                                                                <fa-icon :icon="['fas', 'close']" style="cursor: pointer;" @click="openModalDeleteObject('Vehicle', vehicle.vehicleId)"/>
                                                            </span>
                                                        </span>
                                                        <b-row style="margin: 0; padding: 0">
                                                            <b-col class="d-flex flex-column" style="margin: 0; padding: 0" :md="vehicle.documents && vehicle.documents.length > 0 ? 9 : 12">
                                                                <div style="margin: 5px">
                                                                    <div><strong>Plate: </strong> {{vehicle.state ? '(' + vehicle.state + ')' : ''}}{{vehicle.plate ? vehicle.plate : 'Unknown'}}</div>
                                                                    <div><strong>Color: </strong>{{vehicle.color}}</div>
                                                                    <div><strong>VIN: </strong>{{vehicle.vin}}</div>
                                                                    <div><strong>Registered Owner: </strong>{{vehicle.registeredOwner}}</div>
                                                                    <div><strong>Description: </strong>{{vehicle.description}}</div>
                                                                </div>
                                                            </b-col>
                                                            <b-col style="margin: 0; padding: 0" md="3" class="d-flex justify-content-center" v-if="vehicle.documents && vehicle.documents.length > 0">
                                                                <span style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #282d32;" class="image-parent">
                                                                    <img class="img-center-ass" style="border-radius: 5px;"
                                                                         v-if="vehicle.documents && vehicle.documents.length > 0"
                                                                         v-auth-image="getImage(vehicle.documents[0].storageId)"
                                                                         @click="openViewModal(vehicle.documents[0].storageId)"
                                                                         alt=""/>
                                                                    <img v-else class="img-center-ass" style="border-radius: 5px;" src="/NoImageAvailable.png" alt="">
                                                                </span>
                                                            </b-col>
                                                        </b-row>
                                                        <div v-if="!profileObjects.vehicles.noteExpand[index]" style="text-align: center">
                                                            <div style="text-align: center; cursor: pointer;" @click="profileObjects.vehicles.noteExpand.splice(index, 1, true)">
                                                                <fa-icon :icon="['fas', 'angle-down']"/>
                                                            </div>
                                                        </div>
                                                        <div v-else style="padding: 5px">
                                                            <br>
                                                            <h4 style="margin-bottom: 5px;"><strong>Notes </strong></h4>
                                                            <div v-if="verifyThereAreNoNotes(vehicle.notes.filter(_ => !_.scope || _.scope === intelCaseId || _.scope === 'global'))" style="width: 100%; display: flex; align-items: center; padding-left: 10px">
                                                                <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313">
                                                                    <span style="text-align: center; width: 100%">no notes to display</span>
                                                                </b-card>
                                                            </div>
                                                            <div v-else style="padding-left: 10px">
                                                                <div :key="i" v-for="(note, i) in vehicle.notes.filter(_ => !_.scope || _.scope === intelCaseId || _.scope === 'global')">
                                                                    <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313" class="d-flex flex-row justify-content-between flex-wrap">
                                                                        <div v-if="note.text !== ''" style="width: fit-content; word-break: break-word;"><b>{{(i+1) + ") "}}</b>{{note.text}}</div>
                                                                        <div class="text-muted text-uppercase font-weight-bold font-xs flex-grow-1 text-right"
                                                                             style="width: fit-content">Created By: {{note.createdBy}}
                                                                        </div>
                                                                    </b-card>
                                                                </div>
                                                            </div><br>
                                                            <div class="d-flex flex-wrap-reverse">
                                                                    <div class="d-flex flex-row align-items-center">
                                                                        <h4 style="margin-bottom: 5px"><strong>Documents/Media</strong></h4>&nbsp;
                                                                        <b-badge variant="success" style="height: fit-content; margin-bottom: 5px" @click="openModalAddVehicleDocuments(index)">
                                                                            <fa-icon :icon="['fas', 'plus']"/>Add
                                                                        </b-badge>
                                                                    </div>
                                                                    <fa-icon :icon="['fas', 'right-left']" v-if="vehicle.documents && vehicle.documents.length > 1"
                                                                       @click="openModalFileSelector(vehicle.documents, 'vehicles', index)"
                                                                       class="d-flex justify-content-end"
                                                                       style="flex-grow: 1; margin: 3px; cursor: pointer;"/>
                                                            </div>
                                                            <v-client-table style="width: 100%; margin-left: 10px; padding-right: 16px" :columns="dTable.columns" :data="vehicle.documents ? vehicle.documents : []" :options="dTable.tOptions" :theme="dTable.theme">
                                                                <div slot="file" slot-scope="props">
                                                                    <b-col style="margin: 0; padding: 0" xl="5" class="d-flex justify-content-center" v-if="vehicle.documents && vehicle.documents.length > 0">
                                                                        <span style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #282d32;" class="image-parent">
                                                                            <img v-auth-image="getImage(props.row.storageId)" style="max-height: 50px;" @click="openViewModal(props.row.storageId)" alt=""/>
                                                                        </span>
                                                                    </b-col>
                                                                </div>
                                                                <div slot="edit_delete" slot-scope="props" style="float: right">
                                                                    <fa-icon :icon="['fas', 'pen-to-square']" style="cursor: pointer;" @click="openModalEditVDocuments(props.row, index, props.row.documentId)"/>&nbsp;
                                                                    <fa-icon :icon="['fas', 'close']" style="cursor: pointer;" @click="openModalDeleteVD('Vehicle Document', props.row.documentId, index)"/>
                                                                </div>
                                                            </v-client-table>
                                                            <p style="text-align: center; margin: 0; cursor: pointer;" @click="profileObjects.vehicles.noteExpand.splice(index, 1, false)">
                                                                <fa-icon :icon="['fas', 'angle-up']" style="text-align: center"/>
                                                            </p>
                                                        </div>
                                                    </b-card>
                                                </span>
                                                <div style="text-align: center">
                                                    <span @click="openModalAddVehicles()">
                                                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;"/> &nbsp;new vehicle
                                                    </span>
                                                </div>
                                            </b-col>
                                        </b-tab>

                                        <!--LE-Reports-->
                                        <b-tab title="LE-Reports" class="tab-scroll">
                                            <b-col style="padding: 0">
                                                <span :key="index" v-for="(report, index) in curIntelCase.leReports">
                                                    <b-card style="border-color: #0e0e0e;background-color: #202020;" no-body>
                                                        <span class="d-flex w-100 justify-content-between" style="background-color: #0e0e0e; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                                                            <b style="font-size: medium">{{report.leReportNumber}}</b>
                                                            <span>
                                                                <fa-icon :icon="['fas', 'pen-to-square']" style="cursor: pointer;" @click="openModalEditLeReports(report)"/>&nbsp;
                                                                <fa-icon :icon="['fas', 'close']" style="cursor: pointer;" @click="openModalDeleteObject('LE-Report', report.leReportId)"/>
                                                            </span>
                                                        </span>
                                                        <b-row style="margin: 0; padding: 0">
                                                            <b-col class="d-flex flex-column" style="margin: 0; padding: 0" :md="report.documents && report.documents.length > 0 ? 9 : 12">
                                                                <div style="margin: 5px">
                                                                    <div><strong>Subject: </strong>{{report.subject}}</div>
                                                                    <div><strong>Involvement: </strong>{{report.involvement}}</div>
                                                                    <div><strong>Date: </strong>{{dateToString(report.date)}}</div>
                                                                    <div><strong>Description: </strong>{{report.description}}</div>
                                                                </div>
                                                            </b-col>
                                                            <b-col style="margin: 0; padding: 0" md="3" class="d-flex justify-content-center" v-if="report.documents && report.documents.length > 0">
                                                                <span style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #282d32;" class="image-parent">
                                                                    <img class="img-center-ass" style="border-radius: 5px;"
                                                                         v-if="report.documents && report.documents.length > 0"
                                                                         v-auth-image="getImage(report.documents[0].storageId)"
                                                                         @click="openViewModal(report.documents[0].storageId)"
                                                                         alt=""/>
                                                                    <img v-else class="img-center-ass" style="border-radius: 5px;" src="/NoImageAvailable.png" alt="">
                                                                </span>
                                                            </b-col>
                                                        </b-row>
                                                        <div  v-if="!profileObjects.leReports.noteExpand[index]" style="text-align: center">
                                                            <div style="text-align: center; cursor: pointer;" @click="profileObjects.leReports.noteExpand.splice(index, 1, true)">
                                                                <fa-icon :icon="['fas', 'angle-down']"/>
                                                            </div>
                                                        </div>
                                                        <div v-else style="margin: 5px">
                                                            <br/>
                                                            <h4 style="margin-bottom: 5px;"><strong>Notes </strong></h4>
                                                            <div v-if="verifyThereAreNoNotes(report.notes.filter(_ => !_.scope || _.scope === intelCaseId || _.scope === 'global'))" style="width: 100%; display: flex; align-items: center; padding-left: 10px">
                                                                <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313">
                                                                    <span style="text-align: center; width: 100%">no notes to display</span>
                                                                </b-card>
                                                            </div>
                                                            <div v-else style="padding-left: 10px">
                                                                <div :key="i" v-for="(note, i) in report.notes.filter(_ => !_.scope || _.scope === intelCaseId || _.scope === 'global')">
                                                                    <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313" class="d-flex flex-row justify-content-between flex-wrap">
                                                                        <div v-if="note.text !== ''" style="width: fit-content; word-break: break-word;"><b>{{(i+1) + ") "}}</b>{{note.text}}</div>
                                                                        <div class="text-muted text-uppercase font-weight-bold font-xs flex-grow-1 text-right"
                                                                             style="width: fit-content">Created By: {{note.createdBy}}
                                                                        </div>
                                                                    </b-card>
                                                                </div>
                                                            </div><br>
                                                            <div class="d-flex flex-wrap-reverse">
                                                                <div class="d-flex flex-row align-items-center">
                                                                    <h4 style="margin-bottom: 5px"><strong>Documents/Media</strong></h4>&nbsp;
                                                                    <b-badge variant="success" style="height: fit-content; margin-bottom: 5px" @click="openModalAddLeReportDocuments(index)">
                                                                        <fa-icon :icon="['fas', 'plus']"/>Add
                                                                    </b-badge>
                                                                </div>
                                                                <fa-icon v-if="report.documents && report.documents.length > 1" :icon="['fas', 'right-left']"
                                                                   @click="openModalFileSelector(report.documents, 'leReports', index)"
                                                                   class="d-flex justify-content-end"
                                                                   style="flex-grow: 1; margin: 3px; cursor: pointer;"/>
                                                            </div>
                                                            <v-client-table style="width: 100%; margin-left: 10px; padding-right: 16px" :columns="dTable.columns" :data="report.documents ? report.documents : []" :options="dTable.tOptions" :theme="dTable.theme">
                                                                <div slot="file" slot-scope="props">
                                                                    <img v-auth-image="getImage(props.row.storageId)" style="max-height: 50px;"
                                                                         @click="openViewModal(props.row.storageId)" alt=""/>
                                                                </div>
                                                                <div slot="edit_delete" slot-scope="props" style="float: right">
                                                                    <fa-icon :icon="['fas', 'pen-to-square']" style="cursor: pointer;" @click="openModalEditLEDocuments(props.row, index, props.row.documentId)"/>&nbsp;
                                                                    <fa-icon :icon="['fas', 'close']" style="cursor: pointer;" @click="openModalDeleteVD('LE-Report Document', props.row.documentId, index)"/>
                                                                </div>
                                                            </v-client-table>
                                                            <div class="d-flex flex-row align-items-center">
                                                                <img style="height: 24px; margin-bottom: 5px;" src="https://maps.google.com/mapfiles/ms/icons/green-dot.png" alt=""/>
                                                                <h4 style="margin-bottom: 5px"><strong>Addresses</strong></h4>&nbsp;
                                                                <b-badge variant="success" style="height: fit-content; margin-bottom: 5px"
                                                                         @click="openModalAddLeReportAddresses(report)">
                                                                    <fa-icon :icon="['fas', 'plus']"/>Add
                                                                </b-badge>
                                                            </div>
                                                            <div v-for="(address, i) in report.addresses" :key="i+'keyy'" style="padding-left: 10px;">
                                                                <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313">
                                                                    <span class="d-flex w-100 justify-content-between">
                                                                        <span :class="'badge badge-' + (address.active ? 'success' : 'danger')"
                                                                              style="margin: 5px;">
                                                                            {{(address.active ? "Active" : "Not Active")}}
                                                                        </span>
                                                                        <span>
                                                                            <fa-icon :icon="['fas', 'pen-to-square']" style="cursor: pointer;" @click="openModalEditAddresses(address)"/>
                                                                            <fa-icon :icon="['fas', 'close']" style="margin: 5px 2px 0; cursor: pointer;" @click="openModalDeleteVD('LE-Report Address', address.addressId, report.leReportId)"/>
                                                                        </span>
                                                                    </span>
                                                                    <strong v-if="address.address1 !== ''" style="text-align: center; font-size: 15px">{{address.address1}}<br></strong>
                                                                    <strong v-if="address.address2 !== ''" style="text-align: center; font-size: 15px">{{address.address2}}<br></strong>
                                                                    <strong style="text-align: center; font-size: 15px" v-if="address.city !== ''">
                                                                        {{address.city + ", " + address.state + " " + address.zip}}
                                                                    </strong>
                                                                    <strong style="text-align: center; font-size: 15px" v-else>
                                                                        {{address.state + " " + address.zip}}
                                                                    </strong>
                                                                </b-card>
                                                            </div>
                                                            <div v-if="report.addresses && report.addresses.length === 0"
                                                                 style="width: 100%; display: flex; align-items: center">
                                                                <span style="text-align: center; width: 100%">no addresses to display</span>
                                                            </div>
                                                            <p style="text-align: center; margin: 0; cursor: pointer;" @click="profileObjects.leReports.noteExpand.splice(index, 1, false)">
                                                                <fa-icon :icon="['fas', 'angle-up']" style="text-align: center"/>
                                                            </p>
                                                        </div>
                                                    </b-card>
                                                </span>
                                                <span v-if="curIntelCase.leReports && curIntelCase.leReports.length > 0"/>
                                                <p class="noAdded" v-else style="text-align: center;"><strong>No LE-Reports Added</strong></p>
                                                <div style="text-align: center">
                                                    <span @click="openModalAddLeReport()">
                                                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;"/>&nbsp;New LE-Report
                                                    </span>
                                                </div>
                                            </b-col>
                                        </b-tab>

                                        <!--Profile Addresses-->
                                        <b-tab title="Profile Addresses" class="tab-scroll">
                                            <b-row>
                                                <b-col :key="index" lg="12" v-for="(address, index) in profileAddresses">
                                                    <b-card no-body style="border-color: #0e0e0e;background-color: #202020;" v-if="profileAddresses.length > 0">
                                                        <span class="d-flex w-100 justify-content-between" style="background-color: #0e0e0e; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                                                            <span class="d-flex flex-row align-items-center">
                                                                    <img style="height: 21px" src="http://maps.google.com/mapfiles/ms/icons/red-dot.png" alt=""/>
                                                                <b style="font-size: medium">{{ address.profileName }}</b>&nbsp;
                                                                <b-badge :variant="'badge badge-' + (address.address.active ? 'success' : 'danger')" style="height: fit-content; margin-bottom: 4px">
                                                                    {{(address.address.active ? "Active" : "Not Active")}}
                                                                </b-badge>
                                                            </span>
                                                        </span>
                                                        <b-row style="margin: 0; padding: 0">
                                                            <b-col class="d-flex flex-column" style="margin: 0; padding: 0"
                                                                   :xl="address.address.documents && address.address.documents.length > 0 ? 9 : 12">
                                                                <div style="padding: 5px 0 5px 0; height: 100%">
                                                                    <div class="p-0 d-flex flex-column align-items-center justify-content-center" style="border-bottom: #282d32 1px; border: none; height: 100%">
                                                                        <strong v-if="address.address.address1 !== ''" style="font-size: 15px">{{address.address.address1}}<br></strong>
                                                                        <strong v-if="address.address.address2 !== ''" style="font-size: 15px">{{address.address.address2}}<br></strong>
                                                                        <strong style="text-align: center; font-size: 15px" v-if="address.address.city !== ''">
                                                                            {{address.address.city + ", " + address.address.state + " " + address.address.zip}}
                                                                        </strong>
                                                                        <strong style="text-align: center; font-size: 15px; position: relative" v-else>
                                                                            {{address.address.state + " " + address.address.zip}}
                                                                        </strong>
                                                                        <strong class="text-muted text-uppercase font-weight-bold font-xs d-flex flex-row justify-content-end align-items-end" style="position: absolute;width: 100%; height: 100%; text-align: end; padding: 1px">
                                                                            Created By: {{address.createdBy}}
                                                                        </strong>
                                                                    </div>
                                                                </div>
                                                            </b-col>
                                                            <b-col style="margin: 0; padding: 0" xl="3"
                                                                   class="d-flex justify-content-center"
                                                                   v-if="address.address.documents && address.address.documents.length > 0">
                                                                <span style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #282d32;"
                                                                      class="image-parent">
                                                                    <img class="img-center-ass" style="border-radius: 5px;"
                                                                         v-if="address.address.documents && address.address.documents.length > 0"
                                                                         v-auth-image="getFirstDocumentImage(address.address.documents)"
                                                                         @click="openViewModal(address.address.documents[0].storageId)"
                                                                         alt=""/>
                                                                    <img v-else class="img-center-ass"
                                                                         style="border-radius: 5px;"
                                                                         src="/NoImageAvailable.png"
                                                                         alt="">
                                                                </span>
                                                            </b-col>
                                                        </b-row>
                                                        <div v-if="profileAddresses[index].noteExpand">
                                                            <br>
                                                            <div style="padding: 10px">
                                                                <h4 style="margin-bottom: 5px;"><strong>Notes </strong></h4>
                                                                <div v-if="verifyThereAreNoNotes(address.address.notes.filter(_ => !_.scope || _.scope === 'profile' + address.profileId || _.scope === 'global'))" style="width: 100%; display: flex; align-items: center; padding-left: 10px">
                                                                    <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313">
                                                                        <span style="text-align: center; width: 100%">no notes to display</span>
                                                                    </b-card>
                                                                </div>
                                                                <div v-else>
                                                                    <div :key="i" v-for="(note, i) in address.address.notes.filter(_ => !_.scope || _.scope === 'profile' + address.profileId || _.scope === 'global')">
                                                                        <b-card no-body style="margin-bottom: 5px; margin-left: 10px">
                                                                        <span v-if="note.text !== ''"><strong>{{(i+1) + ") "}}</strong>{{note.text}}</span>
                                                                         <div class="text-muted text-uppercase font-weight-bold font-xs"
                                                                              style=" float: right; margin-right: 10px;">Created By: {{note.createdBy}}
                                                                                </div>
                                                                        </b-card>
                                                                    </div>
                                                                </div><br>
                                                                <h4 style="margin-bottom: 5px"><strong>Documents/Media</strong></h4>
                                                                <div style="padding-left: 10px">
                                                                    <v-client-table :columns="dTable.uneditableColumns"
                                                                                    :data="address.address.documents ? address.address.documents : []"
                                                                                    :options="dTable.tOptions" :theme="dTable.theme">
                                                                        <div slot="file" slot-scope="props">
                                                                            <img v-auth-image="getImage(props.row.storageId)" style="max-height: 50px;"
                                                                                 @click="openViewModal(props.row.storageId)" alt=""/>
                                                                        </div>
                                                                        <span slot="h__edit_delete" style="float: right">Edit/Delete</span>
                                                                    </v-client-table>
                                                                </div>
                                                                <p style="text-align: center; cursor: pointer;" @click="profileAddresses[index].noteExpand = false">
                                                                    <fa-icon :icon="['fas', 'angle-up']" style="text-align: center;"/>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div v-else style="text-align: center; margin: 0; border-radius: 3px; z-index: 1; cursor: pointer;" @click="profileAddresses[index].noteExpand = true">
                                                            <fa-icon :icon="['fas', 'angle-down']"/>
                                                        </div>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                        </b-tab>

                                        <!--Profile Vehicles-->
                                        <b-tab title="Profile Vehicles" class="tab-scroll">
                                            <b-row>
                                                <b-col :key="index" lg="12" v-for="(vehicle, index) in profileVehicles">
                                                    <b-card no-body style="border-color: #0e0e0e;background-color: #202020;" v-if="profileVehicles.length > 0">
                                                        <span class="d-flex w-100 justify-content-between" style="background-color: #0e0e0e; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                                                            <b style="font-size: medium">{{vehicle.vehicle.year}} {{vehicle.vehicle.make}} {{vehicle.vehicle.model}}</b>
                                                            <b>{{ vehicle.profileName }}</b>
                                                        </span>
                                                        <b-row style="margin: 0; padding: 0">
                                                            <b-col class="d-flex flex-column" style="margin: 0; padding: 0" :md="vehicle.vehicle.documents && vehicle.vehicle.documents.length > 0 ? 9 : 12">
                                                                <div style="margin: 5px">
                                                                    <div><strong>Plate: </strong> {{vehicle.vehicle.state ? '(' + vehicle.vehicle.state + ')' : ''}}{{vehicle.vehicle.plate ? vehicle.vehicle.plate : 'Unknown'}}</div>
                                                                    <div><strong>Color: </strong>{{vehicle.vehicle.color}}</div>
                                                                    <div><strong>VIN: </strong>{{vehicle.vehicle.vin}}</div>
                                                                    <div><strong>Registered Owner: </strong>{{vehicle.vehicle.registeredOwner}}</div>
                                                                    <div><strong>Description: </strong>{{vehicle.vehicle.description}}</div>
                                                                </div>
                                                            </b-col>
                                                            <b-col style="margin: 0; padding: 0" md="3" class="d-flex justify-content-center" v-if="vehicle.vehicle.documents && vehicle.vehicle.documents.length > 0">
                                                                <span style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #282d32;" class="image-parent">
                                                                    <img class="img-center-ass" style="border-radius: 5px;"
                                                                         v-if="vehicle.vehicle.documents && vehicle.vehicle.documents.length > 0"
                                                                         v-auth-image="getFirstDocumentImage(vehicle.vehicle.documents)"
                                                                         @click="openViewModal(vehicle.vehicle.documents[0].storageId)"
                                                                         alt=""/>
                                                                    <img v-else class="img-center-ass" style="border-radius: 5px;" src="/NoImageAvailable.png" alt="">
                                                                </span>
                                                            </b-col>
                                                        </b-row>
                                                        <div v-if="profileVehicles[index].noteExpand" style="margin: 5px">
                                                            <br/>
                                                            <h4 style="margin-bottom: 5px;"><strong>Notes </strong></h4>
                                                            <div v-if="verifyThereAreNoNotes(vehicle.vehicle.notes.filter(_ => !_.scope || _.scope === 'profile' + vehicle.profileId || _.scope === 'global'))" style="width: 100%; display: flex; align-items: center; padding-left: 10px">
                                                                <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313">
                                                                    <span style="text-align: center; width: 100%">no notes to display</span>
                                                                </b-card>
                                                            </div>
                                                            <div v-else style="padding-left: 10px">
                                                                <div :key="i" v-for="(note, i) in vehicle.vehicle.notes.filter(_ => !_.scope || _.scope === 'profile' + vehicle.profileId || _.scope === 'global')">
                                                                    <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313" class="d-flex flex-row justify-content-between flex-wrap">
                                                                        <div v-if="note.text !== ''" style="width: fit-content; word-break: break-word;"><b>{{(i+1) + ") "}}</b>{{note.text}}</div>
                                                                        <div class="text-muted text-uppercase font-weight-bold font-xs flex-grow-1 text-right"
                                                                             style="width: fit-content">Created By: {{note.createdBy}}
                                                                        </div>
                                                                    </b-card>
                                                                </div>
                                                            </div><br>
                                                            <h4 style="margin-bottom: 5px"><strong>Documents/Media</strong></h4>
                                                            <v-client-table style="width: 100%; margin-left: 10px; padding-right: 16px" :columns="dTable.uneditableColumns" :data="vehicle.vehicle.documents ? vehicle.vehicle.documents : []"
                                                                            :options="dTable.tOptions" :theme="dTable.theme">
                                                                <div slot="file" slot-scope="props">
                                                                    <img v-auth-image="getImage(props.row.storageId)" style="max-height: 50px;"
                                                                         @click="openViewModal(props.row.storageId)" alt=""/>
                                                                </div>
                                                            </v-client-table>
                                                            <p style="text-align: center; margin: 0; cursor: pointer;" @click="profileVehicles[index].noteExpand = false">
                                                                <fa-icon :icon="['fas', 'angle-up']" style="text-align: center;"/>
                                                            </p>
                                                        </div>
                                                        <div v-else>
                                                            <div style="text-align: center; cursor: pointer;" @click="profileVehicles[index].noteExpand = true">
                                                                <fa-icon :icon="['fas', 'angle-down']"/>
                                                            </div>
                                                        </div>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                        </b-tab>

                                        <!--Profile LE-Reports-->
                                        <b-tab title="Profile LE-Reports" class="tab-scroll">
                                            <b-col style="padding: 0">
                                                <span :key="index" v-for="(report, index) in profileLeReports">
                                                    <b-card style="border-color: #0e0e0e;background-color: #202020;" no-body>
                                                        <span class="d-flex w-100 justify-content-between" style="background-color: #0e0e0e; padding: 0 5px 0 5px; border-radius: 3px 3px 0 0">
                                                            <b style="font-size: medium">{{ report.number }}</b>
                                                        </span>
                                                        <b-row style="margin: 0; padding: 0">
                                                            <b-col class="d-flex flex-column" style="margin: 0; padding: 0" :md="report.documents && report.documents.length > 0 ? 9 : 12">
                                                                <div style="margin: 5px">
                                                                    <div><strong>Involvement: </strong>{{report.involvement}}</div>
                                                                    <div><strong>Description: </strong>{{report.description}}</div>
                                                                </div>
                                                            </b-col>
                                                            <b-col style="margin: 0; padding: 0" md="3" class="d-flex justify-content-center" v-if="report.documents && report.documents.length > 0">
                                                                <span style="width: auto; max-height: 100px; margin: 5px; border: 2px solid #23282c; border-radius: 5px; background-color: #282d32;" class="image-parent">
                                                                    <img class="img-center-ass" style="border-radius: 5px;"
                                                                         v-if="report.documents && report.documents.length > 0"
                                                                         v-auth-image="getFirstDocumentImage(report.documents)"
                                                                         @click="openViewModal(report.documents[0].storageId)"
                                                                         alt=""/>
                                                                    <img v-else class="img-center-ass" style="border-radius: 5px;" src="/NoImageAvailable.png" alt="">
                                                                </span>
                                                            </b-col>
                                                        </b-row>
                                                        <div v-if="profileLeReports[index].noteExpand === true" style="margin: 5px">
                                                            <br/>
                                                            <h4 style="margin-bottom: 5px;"><strong>Notes </strong></h4>
                                                            <div v-if="verifyThereAreNoNotes(report.notes.filter(_ => !_.scope || _.scope === 'profile' + report.profileId || _.scope === 'global'))" style="width: 100%; display: flex; align-items: center; padding-left: 10px">
                                                                <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313">
                                                                    <span style="text-align: center; width: 100%">no notes to display</span>
                                                                </b-card>
                                                            </div>
                                                            <div v-else style="padding-left: 10px">
                                                                <div :key="i" v-for="(note, i) in report.notes.filter(_ => !_.scope || _.scope === 'profile' + report.profileId || _.scope === 'global')">
                                                                    <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313" class="d-flex flex-row justify-content-between flex-wrap">
                                                                        <div v-if="note.text !== ''" style="width: fit-content; word-break: break-word;"><b>{{(i+1) + ") "}}</b>{{note.text}}</div>
                                                                        <div class="text-muted text-uppercase font-weight-bold font-xs flex-grow-1 text-right"
                                                                             style="width: fit-content">Created By: {{note.createdBy}}
                                                                        </div>
                                                                    </b-card>
                                                                </div>
                                                            </div><br>
                                                            <h4 style="margin-bottom: 5px"><strong>Documents/Media</strong></h4>
                                                            <v-client-table style="width: 100%; margin-left: 10px; padding-right: 16px" :columns="dTable.uneditableColumns" :data="report.documents ? report.documents : []"
                                                                            :options="dTable.tOptions" :theme="dTable.theme">
                                                                <div slot="file" slot-scope="props">
                                                                    <img v-auth-image="getImage(props.row.storageId)" style="max-height: 50px;"
                                                                         @click="openViewModal(props.row.storageId)" alt=""/>
                                                                </div>
                                                            </v-client-table>
                                                            <div class="d-flex flex-row align-items-center">
                                                                <img style="height: 24px; margin-bottom: 5px;" src="https://maps.google.com/mapfiles/ms/icons/orange-dot.png" alt=""/>
                                                                <h4 style="margin-bottom: 5px"><strong>Addresses</strong></h4>
                                                            </div>
                                                            <div v-for="(address, i) in report.addresses" :key="i+'keyy'" style="padding-left: 10px;">
                                                                <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313">
                                                                    <span class="d-flex w-100 justify-content-between">
                                                                        <span :class="'badge badge-' + (address.active ? 'success' : 'danger')"
                                                                              style="margin: 5px;">
                                                                            {{(address.active ? "Active" : "Not Active")}}
                                                                        </span>
                                                                    </span>
                                                                    <strong v-if="address.address1 !== ''" style="text-align: center; font-size: 15px">{{address.address1}}<br></strong>
                                                                    <strong v-if="address.address2 !== ''" style="text-align: center; font-size: 15px">{{address.address2}}<br></strong>
                                                                    <strong style="text-align: center; font-size: 15px" v-if="address.city !== ''">
                                                                        {{address.city + ", " + address.state + " " + address.zip}}
                                                                    </strong>
                                                                    <strong style="text-align: center; font-size: 15px" v-else>
                                                                        {{address.state + " " + address.zip}}
                                                                    </strong>
                                                                </b-card>
                                                            </div>
                                                            <div v-if="report.addresses && report.addresses.length === 0"
                                                                 style="width: 100%; display: flex; align-items: center">
                                                                <span style="text-align: center; width: 100%">no addresses to display</span>
                                                            </div>
                                                            <p style="text-align: center; margin: 0; cursor: pointer;" @click="profileLeReports[index].noteExpand = false">
                                                                <fa-icon :icon="['fas', 'angle-up']" style="text-align: center;"/>
                                                            </p>
                                                        </div>
                                                        <div v-else>
                                                            <div style="text-align: center; cursor: pointer;" @click="profileLeReports[index].noteExpand = true">
                                                                <fa-icon :icon="['fas', 'angle-down']"/>
                                                            </div>
                                                        </div>
                                                        <b-list-group style="border: none" :key="sIndex" v-for="(profile, sIndex) in report.profiles">
                                                            <b-list-group-item style="margin: 5px; background-color: #0e0e0e">
                                                                <span class="d-flex w-100 justify-content-between">
                                                                    <h5 style="margin: 0">{{ profile.name }}</h5>
                                                                    <span>
                                                                        <router-link class="non-link" :to="{ name: 'Profile Details', params: {id: profile.id}}" target="_blank">
                                                                            <fa-icon :icon="['fas', 'up-right-from-square']"/>
                                                                        </router-link>
                                                                    </span>
                                                                </span>
                                                            </b-list-group-item>
                                                        </b-list-group>
                                                    </b-card>
                                                </span>
                                            </b-col>
                                        </b-tab>

                                    </b-tabs>
                                </b-card>
                            </b-col>

                            <!--Map-->
                            <b-col xl="4" style="padding: 0">
                                <b-card bg-variant="dark" header-tag="header" no-body
                                        style="border-color: #121517; background-color: #282d32; margin: 5px; height: 530px">
                                    <div slot="header">Map</div>
                                    <gmap-map ref="myMap2" :center="center" :options="mapStyle" :zoom="11" style="height: 100%; width: 100%">
                                        <gmap-info-window :opened="infoWinOpen" :options="infoOptions"
                                                          :position="infoWindowPos" @closeclick="infoWinOpen=false">
                                            <b-link :href="infoLink" target="_blank">{{infoContent}}</b-link>
                                        </gmap-info-window>
                                        <gmap-marker icon="https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                                                :clickable="true" :draggable="m.draggable" :key="index + 'Id'"
                                                :label="m.label"
                                                :position="m.position" :title="m.title"
                                                @click="center=m.position; linkToGoogleMaps(m)"
                                                @mouseover="toggleInfoWindow(m)"
                                                @mouseout="markerWindow.open=false"
                                                v-for="(m, index) in markers">
                                        </gmap-marker>
                                        <gmap-marker icon="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                                                :clickable="true" :draggable="m.draggable" :key="index"
                                                :label="m.label"
                                                :position="m.position" :title="m.title"
                                                @click="center=m.position; linkToGoogleMaps(m)"
                                                @mouseover="toggleInfoWindow(m)"
                                                @mouseout="markerWindow.open=false"
                                                v-for="(m, index) in profileMarkers">
                                        </gmap-marker>
                                        <gmap-marker icon="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                                                :clickable="true" :draggable="m.draggable" :key="index + 'report'"
                                                :label="m.label"
                                                :position="m.position" :title="m.title"
                                                @click="center=m.position; linkToGoogleMaps(m)"
                                                @mouseover="toggleInfoWindow(m)"
                                                @mouseout="markerWindow.open=false"
                                                v-for="(m, index) in leReportMarkers">
                                        </gmap-marker>
                                        <gmap-marker icon="https://maps.google.com/mapfiles/ms/icons/orange-dot.png"
                                                     :clickable="true" :draggable="m.draggable" :key="index + 'profileR'"
                                                     :label="m.label"
                                                     :position="m.position" :title="m.title"
                                                     @click="center=m.position; linkToGoogleMaps(m)"
                                                     @mouseover="toggleInfoWindow(m)"
                                                     @mouseout="markerWindow.open=false"
                                                     v-for="(m, index) in profileLeReportMarkers">
                                        </gmap-marker>
                                        <gmap-info-window
                                                :opened="markerWindow.open"
                                                @closeclick="markerWindow.open=false"
                                                :position="markerWindow.position"
                                                :options="{
                                                    pixelOffset: {
                                                        width: 0,
                                                        height: -35
                                                        }
                                                    }">
                                            <div style="color: black;">
                                                {{ markerWindow.address }}
                                            </div>
                                        </gmap-info-window>
                                    </gmap-map>
                                </b-card>
                            </b-col>

                        </b-row>
                    </span>

                    <!--Members-->
                    <b-card bg-variant="dark" no-body style="border-color: #121517; background-color: #282d32; margin: 5px; height: 530px">

                        <!--Members Header-->
                        <div slot="header">
                            <div class="d-flex w-100 justify-content-between">
                                <p style="margin-bottom: 0"><b>Linked Profiles</b></p>
                                <fa-icon :icon="['fas', 'pen-to-square']" style="cursor: pointer;" size="lg" @click="openModalProfile()"/>
                            </div>
                        </div>

                        <!--Associated Profiles Cards-->
                        <b-row style="margin: 0; height: 483px; overflow-y: auto" align-v="stretch">
                            <span v-if="profiles.linked && profiles.linked.length > 0" style="display: flex; flex-wrap: wrap">
                                <b-card v-for="(profile, index) in profiles.linked" :key="index + 'Id'" style="width: 250px; height: 420px; margin: 10px; background-color: #0e0e0e; padding: 0" no-body>
                                    <span class="d-flex justify-content-between">
                                        <span/>
                                        <strong style="text-align: center;">{{nameify(profile)}}</strong>
                                        <router-link class="non-link" :to="{ name: 'Profile Details', params: {id: profile.profileId}}" target="_blank">
                                            <fa-icon :icon="['fas', 'up-right-from-square']" style="cursor: pointer;"/>
                                        </router-link>
                                    </span>
                                    <div class="image-parent">
                                        <img v-auth-image="associatesImage(profile.images)" class="img-center" alt=""/>
                                    </div>
                                    <div v-if="profile.ribbons && Array.isArray(profile.ribbons) && profile.ribbons.length > 0"
                                          style="display: flex; align-items: center; height: 17px; width: 250px">
                                        <div v-for="(r, i) in profile.ribbons" :key="i + 'r1'" :class="'bg-' + ribbonVariant(r.ribbon)"
                                              style="flex: 1; height: 100%; display: flex; align-items: center">
                                            <p style="margin: 0; width: 100%; text-align: center; font-size: 12px">{{r.ribbon}}</p>
                                        </div>
                                    </div>
                                    <strong style="text-align: center;">
                                        <span v-if="profile.birthMonth">{{profile.birthMonth}}</span>
                                        <span v-else>XX</span>
                                        <span v-if="profile.birthDay">/{{profile.birthDay}}</span>
                                        <span v-else>/XX</span>
                                        <span v-if="profile.birthYear">/{{profile.birthYear}}</span>
                                        <span v-else>/XXXX</span>
                                    </strong>
                                    <strong style="text-align: center"  v-if="profile.addresses !== null && profile.addresses.length > 0">
                                        {{findAddress(profile.addresses)}}
                                    </strong>
                                </b-card>
                            </span>
                        </b-row>

                    </b-card>

                </b-col>

                <!--Description/Notes-->
                <b-col sm="3" class="d-flex flex-column" style="padding: 0">

                    <!--Description-->
                    <b-card bg-variant="dark" no-body style="border-color: #121517; background-color: #282d32; margin: 5px; height: 354px">
                        <div slot="header">
                            <p style="margin-bottom: 0"><b>Description</b></p>
                        </div>
                        <span style="overflow-y: auto; padding: 10px; white-space: pre-line">{{curIntelCase.description}}</span>
                    </b-card>

                    <!--Notes-->
                    <b-card bg-variant="dark" no-body style="border-color: #121517; background-color: #282d32; margin: 5px; height: 702px">
                        <span slot="header">
                            <strong>Notes</strong>
                            <fa-icon v-if="!notesNewToOld" :icon="['fas', 'sort-down']" @click="reverseNotesArray(); notesNewToOld = true"
                               style="float: right; cursor: pointer;"/>
                            <fa-icon v-if="notesNewToOld" :icon="['fas', 'sort-up']" @click="reverseNotesArray(); notesNewToOld = false"
                               style="float: right; cursor: pointer;"/>
                        </span>
                        <span style="overflow-y: auto; padding: 10px">
                            <span :key="index" v-for="(note,index) in curIntelCase.notes">
                                <Note :notes="curIntelCase.notes" :index="index" :method1="changeNoteText" :method2="openModalDeleteObject"
                                      v-model="note.text"/>
                            </span>
                        </span>
                        <div style="text-align: center">
                            <p @click="profileObjects.notes.modal = true">
                                <fa-icon :icon="['fas', 'circle-plus']" style="padding-top: 10px; cursor: pointer;"/>
                                &nbsp;Add Note
                            </p>
                        </div>

                    </b-card>

                </b-col>

            </b-row>

        </b-card>

    </div>
</template>

<script>
    import axios from "axios";
    import risc from '@/services/risc.js'
    import fcr from '@/services/fcr.js';
    import Vue from 'vue'
    import formSelectSearch from "../formSelectSearch";
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import {ClientTable} from 'vue-tables-2'
    import * as VueGoogleMaps from 'vue2-google-maps';
    import * as moment from 'moment';
    import {gmapApi} from "vue2-google-maps";
    import FileSelector from "@/views/expansion_modules/risc/FileSelector";
    import FileViewer from "@/views/expansion_modules/risc/FileViewer";
    import FileUpload from "@/views/expansion_modules/risc/FileUpload";
    import Note from "@/views/expansion_modules/risc/Note";

    Vue.use(ClientTable)
    Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyDiNNTEEV7dTzuxZW1NCvR26RmHmOUhg2U',
            libraries: 'places'
        }
    });

    export default {
        name: "Main",

        props: ["id"],

        components: {
            formSelectSearch,
            datePicker,
            FileSelector,
            FileViewer,
            FileUpload,
            Note
        },

        data() {
            return {
                intelCaseId: "intel case" + this.$route.params.id,
                tabs: null,
                profiles: {
                    all: [],
                    linked: [],
                    selected: [],
                    modal: false,
                    data: [],
                    allMarked: false,
                    theme: 'bootstrap4',
                    columns: ['check', 'tableName', 'tableDOB'],
                    tOptions: {
                        sortable: ['tableName', 'tableDOB'],
                        filterable: ['tableName', 'tableDOB'],
                        orderBy: {column: 'name', ascending: true},
                        headings: {
                            tableName: 'Name',
                            tableDOB: 'DOB',
                        },
                        sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                        pagination: {
                            chunk: 5,
                            edge: false,
                            nav: 'scroll'
                        },
                        perPage: 25,
                        skin: 'table table-striped table-hover',
                    },
                    useVuex: false,
                    template: 'default',
                    createCaseModal: false
                },
                profileLeReports: [],
                profileAddresses: [],
                profileVehicles: [],
                linkedAssociates: [],
                intelCaseNameVerification: '',
                refresh: true,
                curIntelCase: {
                    documents: [],
                    vehicles: [],
                    addresses: []
                },
                profileObjects: {
                    addresses: {
                        allAddresses: [],
                        postPut: false,
                        user: [],
                        data: {},
                        vehicle: [],
                        noteExpand: [],
                        fields:{
                            address1: '',
                            address2: '',
                            city: '',
                            state: '',
                            zip: null,
                            notes: [{text: '', scope: this.intelCaseId}],
                            addressId: '',
                        },
                        addAddressId: null,
                        add: false,
                        table: {
                            theme: 'bootstrap4',
                            columns: ['Select', 'address1', 'address2', 'city', 'state', 'zip'],
                            tOptions: {
                                filterable: ['address1', 'address2', 'city', 'state', 'zip'],
                                sortable: ['address1', 'address2', 'city', 'state', 'zip'],
                                orderBy: {column: 'file', ascending: true},
                                headings: {
                                    address1: 'Address',
                                    address2: 'Apartment/Unit'
                                },
                                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                                pagination: {
                                    chunk: 5,
                                    edge: false,
                                    nav: 'scroll'
                                },
                                perPage: 10,
                                skin: 'table table-striped table-hover',
                            },
                            useVuex: false,
                            template: 'default',
                            createCaseModal: false
                        },
                    },
                    vehicles: {
                        noteExpand: [],
                        postPut: false,
                        docPostPut: false,
                        user: [],
                        data: {},
                        fields:{
                            make: '',
                            model: '',
                            year: 0,
                            plate: '',
                            color: '',
                            description: '',
                            notes: [{text: '', scope: this.intelCaseId}],
                            documents: [],
                            vehicleId: '',
                        }

                    },
                    leReports: {
                        postPut: false,
                        user: [],
                        data: {},
                        noteExpand: [],
                        addId: null,
                        add: false,
                        table: {
                            theme: 'bootstrap4',
                            columns: ['Select', 'leReportNumber', 'involvement', 'description', 'createdDate'],
                            tOptions: {
                                filterable: ['leReportNumber', 'involvement', 'description', 'createdDate'],
                                sortable: ['leReportNumber', 'involvement', 'description', 'createdDate'],
                                orderBy: {column: 'file', ascending: true},
                                headings: {
                                    leReportNumber: 'Report Number',
                                    createdDate: 'Created Date'
                                },
                                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                                pagination: {
                                    chunk: 5,
                                    edge: false,
                                    nav: 'scroll'
                                },
                                perPage: 10,
                                skin: 'table table-striped table-hover',
                            },
                            useVuex: false,
                            template: 'default',
                            createCaseModal: false
                        },
                        all: [],
                    },
                    vehicleDescription: '',
                    documentDescription: '',
                    documents: {
                        postPut: false,
                        user: [],
                        data: {},
                        vehicle: []
                    },
                    notes: {
                        modal: false,
                        newNote: '',
                        noteId: null
                    }
                },
                editCase: {
                    modal: false,
                    data: {},
                },
                deleteModal: false,
                deleteObjects: {
                    modal: false,
                    name: "",
                    deleteText: "",
                    id: '',
                },
                deleteVD: {
                    modal: false,
                    name: '',
                    deleteText: '',
                    dId: '',
                    vId: ''
                },
                states: [
                    'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
                    'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
                    'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
                    'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
                    'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
                ],
                fields: {
                    caseName: {field: 'caseName', name: 'Case Name', required: true, format: null},
                    status: {field: 'status', name: 'Status', required: false, format: null},
                    caseNumber: {field: 'caseNumber', name: 'Case Number', required: true},
                    description: {field: 'description', name: 'Description', required: false, format: null},
                    /*profiles: {field: 'profiles', name: 'Profiles', required: false, format: ""},*/
                    notes: {field: 'notes', name: 'Notes', required: false, format: {text: ""}},
                },
                token: localStorage.mtopToken,
                dTable: {
                    theme: 'bootstrap4',
                    columns: ['file', 'description', 'edit_delete'],
                    uneditableColumns: ['file', 'description'],
                    tOptions: {
                        filterable: [],
                        sortable: [],
                        orderBy: {column: 'file', ascending: true},
                        headings: {
                            edit_delete: 'Edit/Delete'
                        },
                        sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                        pagination: {
                            chunk: 5,
                            edge: false,
                            nav: 'scroll'
                        },
                        perPage: 25,
                        skin: 'table table-striped table-hover',
                    },
                    useVuex: false,
                    template: 'default',
                    createCaseModal: false
                },
                documentId: null,
                vehicleId: null,
                vDocumentId: null,
                addressId: null,
                addressDocumentId: null,
                leDocumentId: null,
                fileUploadOpen: false,
                dm: false,
                vdm: false,
                addm: false,
                ledm: false,
                center: {lat: 42.2711, lng: -89.0940},
                infoContent: '',
                infoLink: '',
                infoOptions: {
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                infoWindowPos: {
                    lat: 0,
                    lng: 0
                },
                infoWinOpen: false,
                mapStyle: {
                    styles: [
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#212121"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#212121"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.country",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.locality",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#181818"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#1b1b1b"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#2c2c2c"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#8a8a8a"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#373737"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#3c3c3c"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#20a8d8"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway.controlled_access",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#4e4e4e"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#000000"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#3d3d3d"
                                }
                            ]
                        }
                    ]
                },
                markers: [],
                markerWindow: {
                    open: false,
                    position: { lat: 0, lng: 0 },
                    address: ''
                },
                profileMarkers: [],
                leReportMarkers: [],
                profileLeReportMarkers: [],
                bounds: [],
                noteText: '',
                notesNewToOld: false,
                addressesToMarkersBool: 0,
                addressIdArray: [],
                fileViewStorageId: null,
                fileSelectorFiles: [],
                fieldToBeChanged: null,
                fieldToBeChangedIndex: 0,
                docSelectBool: false,
                vin: '',
                addressInput: '',
                dateOptions: {
                    format: 'MM/DD/YYYY',
                    useCurrent: false
                },
                vehicleMake: '',
                allMakes: [],
                allModels: [],
                linkAddressBool: false
            }
        },

        updated() {
        },

        created() {
            this.getIntelCase();
        },

        beforeCreate() {

        },

        computed: {
            google: gmapApi
        },

        methods: {
            refactorScope(arr) {
                return arr ? arr.map(_ => {_.scope = !_.text ? this.intelCaseId : _.scope; return _}): []
            },
            deleteThisData(type, index) {
                this.profileEdit = JSON.parse(JSON.stringify(this.profile));
                this.profileEdit[type.name].splice(index, 1);
                this.putCase()
            },
            closeModalDelete() {
                this.deleteIndex = null;
                this.deleteVerification = '';
                this.deleteModal = false
            },
            deleteObjectsMethod() {
                let idToRemove;
                switch (this.deleteObjects.name) {
                    case "Note":
                        idToRemove = this.deleteObjects.id
                        this.curIntelCase.notes = this.curIntelCase.notes.filter(function (note) {
                            return note.noteId !== idToRemove
                        })
                        risc.putCase(this.curIntelCase).then(() => {
                            this.getIntelCase()
                        })
                        break;
                    case "Drug":
                        risc.unlinkCaseDrug(this.$route.params.id, this.deleteObjects.id).then(() => {
                            this.getIntelCase()
                        })
                        break;
                    case "Weapon":
                        risc.unlinkCaseWeapon(this.$route.params.id, this.deleteObjects.id).then(() => {
                            this.getIntelCase()
                        })
                        break;
                    case "Vehicle":
                        risc.unlinkCaseVehicle(this.$route.params.id, this.deleteObjects.id).then(() => {
                            this.getIntelCase()
                        })
                        break;
                    case "LE-Report":
                        risc.unlinkCaseLeReport(this.$route.params.id, this.deleteObjects.id).then(() => {
                            this.getIntelCase()
                        })
                        break;
                    case "Address":
                        risc.unlinkCaseAddress(this.$route.params.id, this.deleteObjects.id).then(() => {
                            this.getIntelCase()
                        })
                        break;
                    case "Document":
                        idToRemove = this.deleteObjects.id
                        this.curIntelCase.documents = this.curIntelCase.documents.filter(function (document) {
                            return document.documentId !== idToRemove
                        })
                        break;
                }
                this.deleteObjects.modal = false
            },
            deleteVDMethod() {
                let idToRemove;
                switch (this.deleteVD.name) {
                    case "Vehicle Document":
                        idToRemove = this.deleteVD.dId
                        this.curIntelCase.vehicles[this.deleteVD.vId].documents = this.curIntelCase.vehicles[this.deleteVD.vId].documents.filter(function (document) {
                            return document.documentId !== idToRemove
                        })
                        risc.putVehicle(this.curIntelCase.vehicles[this.deleteVD.vId]).then(() => {
                            this.getIntelCase()
                        })
                        break;
                    case "Address Document":
                        idToRemove = this.deleteVD.dId
                        this.curIntelCase.addresses[this.deleteVD.vId].documents = this.curIntelCase.addresses[this.deleteVD.vId].documents.filter(function (document) {
                            return document.documentId !== idToRemove
                        })
                        risc.putAddress(this.curIntelCase.addresses[this.deleteVD.vId]).then(() => {
                            this.getIntelCase()
                        })
                        break;
                    case "LE-Report Address":
                        risc.unlinkAddressLeReport(this.deleteVD.dId, this.deleteVD.vId).then(() => {
                            this.getIntelCase();
                        })
                        break;
                    case "LE-Report Document":
                        idToRemove = this.deleteVD.dId
                        this.curIntelCase.leReports[this.deleteVD.vId].documents = this.curIntelCase.leReports[this.deleteVD.vId].documents.filter(function (document) {
                            return document.documentId !== idToRemove
                        })
                        risc.putLeReport(this.curIntelCase.leReports[this.deleteVD.vId]).then(() => {
                            this.getIntelCase()
                        })
                        break;
                }
                this.deleteVD.modal = false
            },
            editNote() {
                this.curIntelCase.notes[this.profileObjects.notes.noteId].text = this.noteText
                risc.putCase(this.curIntelCase).then(() => {
                    this.getIntelCase()
                })
                this.$bvModal.hide('edit-note')
            },
            updateProfiles() {
                if (this.profiles.linked.length > 0) { // Checking if there's any previous profiles
                    let countLinked = 0;
                    this.profiles.linked.forEach(Old => { // Looping through old to see if they're selected
                        if (this.profiles.selected.length > 0) { // Checking if there's anything selected
                            for(let j = 0; j < this.profiles.selected.length; j++) { // Looping through selected profiles to see if old one is in it
                                if (this.profiles.selected[j].toString() === Old.profileId.toString()) { // If old profile is in selected, take it out so only newly selected are left
                                    countLinked++;
                                    this.profiles.selected.splice(j, 1);
                                    if (countLinked === this.profiles.linked.length) { // Once done with old profiles, add any leftover selected profiles
                                        if(this.profiles.selected.length > 0) {
                                            let countSelected = 0;
                                            this.profiles.selected.forEach(Add => {
                                                risc.linkCaseProfile(this.$route.params.id, Add).then(() => {
                                                    countSelected++;
                                                    if (countSelected === this.profiles.selected.length) {
                                                        this.profiles.modal = false;
                                                        this.getIntelCase()
                                                    }
                                                });
                                            });
                                        } else {
                                            this.profiles.modal = false;
                                            this.getIntelCase()
                                        }
                                    }
                                    break;
                                }
                                if (j === this.profiles.selected.length - 1) { // If old profile is not in selected, remove it
                                    risc.unlinkCaseProfile(this.$route.params.id, Old.profileId).then(() => {
                                        countLinked++;
                                        if (countLinked === this.profiles.linked.length) { // Once done with old profiles, add any leftover selected profiles
                                            if(this.profiles.selected.length > 0) {
                                                let countSelected = 0;
                                                this.profiles.selected.forEach(Add => {
                                                    risc.linkCaseProfile(this.$route.params.id, Add).then(() => {
                                                        countSelected++;
                                                        if (countSelected === this.profiles.selected.length) {
                                                            this.profiles.modal = false;
                                                            this.getIntelCase()
                                                        }
                                                    });
                                                });
                                            } else {
                                                this.profiles.modal = false;
                                                this.getIntelCase()
                                            }
                                        }
                                    });
                                }
                            }
                        } else { // Nothing was selected, remove old profile
                            risc.unlinkCaseProfile(this.$route.params.id, Old.profileId).then(() => {
                                countLinked++;
                                if (countLinked === this.profiles.linked.length) { // Once done with old profiles, add any leftover selected profiles
                                    if(this.profiles.selected.length > 0) {
                                        let countSelected = 0;
                                        this.profiles.selected.forEach(Add => {
                                            risc.linkCaseProfile(this.$route.params.id, Add).then(() => {
                                                countSelected++;
                                                if (countSelected === this.profiles.selected.length) {
                                                    this.profiles.modal = false;
                                                    this.getIntelCase()
                                                }
                                            });
                                        });
                                    } else {
                                        this.profiles.modal = false;
                                        this.getIntelCase()
                                    }
                                }
                            });
                        }
                    });
                } else { // If no previous profiles, everything checked is new
                    let countSelected = 0;
                    this.profiles.selected.forEach(Add => {
                        risc.linkCaseProfile(this.$route.params.id, Add).then(() => {
                            countSelected++;
                            if(countSelected === this.profiles.selected.length) {
                                this.profiles.modal = false;
                                this.getIntelCase()
                            }
                        });
                    });
                }
            },
            openModalProfile() {
                risc.getProfiles().then(response => {
                    this.profiles.all = response.data.map(profile => {
                        return({
                            ...JSON.parse(JSON.stringify(profile)),
                            tableDOB: this.dateify(profile.birthMonth, profile.birthDay, profile.birthYear),
                            tableName: this.nameify(profile)
                        })
                    })
                    this.profiles.modal = true
                })
            },
            aliasify(arr) {
                let result = ""
                if (Array.isArray(arr)) {
                    arr.forEach((a, i) => {
                        result += (a.knownAlias.length > 0 ? (i + 1 < arr.length ? a.knownAlias : a.knownAlias + ", ") : "")
                    })
                }
                return result;
            },
            close() {
                this.editCase.modal = false
            },
            putCase() {
                risc.putCase(this.editCase.data).then(() => {
                    this.getIntelCase()
                });
                this.close()
            },
            splice(index, field) {
                field.splice(index, 1);
                this.refresh = false;
                this.refresh = true;
            },
            getProfilesById() {
                risc.getProfilesById(this.curIntelCase.profiles.map(a => a.profileId)).then(response => {
                    this.profiles.linked = response.data;
                })
            },
            getIntelCase() {
                this.profiles.linked = [];
                this.profiles.selected = [];
                this.profileVehicles = [];
                this.profileAddresses = [];
                this.profileLeReports = [];
                risc.getCase(this.$route.params.id).then(function (response) {
                    this.curIntelCase = response.data;
                    if (this.notesNewToOld === true) {
                        this.curIntelCase.notes = this.curIntelCase.notes.reverse()
                    }
                    this.profiles.linked = response.data.profiles
                    response.data.profiles.forEach(profile => {
                        this.profiles.selected.push(profile.profileId.toString())
                    });
                    this.getProfilesById();
                    this.makeProfilesDeep();
                    if (!this.curIntelCase.addresses.length || this.curIntelCase.addresses.length === 0) {
                        this.addressesToMarkersBool++
                        if (this.addressesToMarkersBool === 2) {
                            this.addressesToMarkers()
                        }
                    }
                    // makes the vehicles deep to reach the documents data
                    let count = 0
                    let deepVehicles = []
                    this.profileObjects.vehicles.noteExpand = new Array(this.curIntelCase.vehicles.length).fill(false);
                    for (let i = 0; i < this.curIntelCase.vehicles.length; i++) {
                        risc.getVehicle(this.curIntelCase.vehicles[i].vehicleId).then(response => {
                            deepVehicles.push(response.data)
                            count++
                            if (count === this.curIntelCase.vehicles.length) {
                                this.curIntelCase.vehicles = deepVehicles
                            }
                        })
                    }
                    // makes the addresses deep to reach the documents data
                    let count2 = 0
                    let deepAddresses = []
                    this.profileObjects.addresses.noteExpand = new Array(this.curIntelCase.addresses.length).fill(false);
                    for (let i = 0; i < this.curIntelCase.addresses.length; i++) {
                        risc.getAddress(this.curIntelCase.addresses[i].addressId).then(response => {
                            deepAddresses.push(response.data)
                            count2++
                            if (count2 === this.curIntelCase.addresses.length) {
                                this.curIntelCase.addresses = deepAddresses
                                this.addressesToMarkersBool++
                                if (this.addressesToMarkersBool === 2) {
                                    this.addressesToMarkers()
                                }
                            }
                        })
                    }
                    // makes the leReports deep to reach the documents data
                    let count3 = 0
                    let deepLeReports = []
                    this.profileObjects.leReports.noteExpand = new Array(this.curIntelCase.leReports.length).fill(false);
                    for (let i = 0; i < this.curIntelCase.leReports.length; i++) {
                        risc.getLeReport(this.curIntelCase.leReports[i].leReportId).then(response => {
                            deepLeReports.push(response.data)
                            count3++
                            if (count3 === this.curIntelCase.leReports.length) {
                                this.curIntelCase.leReports = Object.assign(deepLeReports)
                                this.curIntelCase.leReports.sort((current, other) => {
                                    let Current = new Date(current.date).getTime()
                                    let Other = new Date(other.date).getTime()
                                    return Other - Current})
                            }
                        })
                    }
                }.bind(this));
            },
            makeProfilesDeep() {
                // makes the profiles deep to reach the documents data
                let count10 = 0
                // let deepProfiles = []
                let deepProfiles = new Array(this.profiles.selected.length).fill({})
                for (let i = 0; i < this.curIntelCase.profiles.length; i++) {
                    risc.getOneProfile(this.curIntelCase.profiles[i].profileId).then(response => {
                        deepProfiles[i] = response.data
                        if (response.data.addresses && response.data.addresses.length > 0) {
                            response.data.addresses.forEach(address => {
                                risc.getAddress(address.addressId).then(response => {
                                    let deepAddress = response.data;
                                    this.profileAddresses.push({
                                        profileId: deepProfiles[i].profileId,
                                        address: deepAddress,
                                        profileName: this.nameify(deepProfiles[i]),
                                        noteExpand: false
                                    })
                                })
                            })
                        }
                        if (response.data.vehicles && response.data.vehicles.length > 0) {
                            response.data.vehicles.forEach(vehicle => {
                                risc.getVehicle(vehicle.vehicleId).then(response => {
                                    let deepVehicle = response.data;
                                    this.profileVehicles.push({
                                        profileId: deepProfiles[i].profileId,
                                        vehicle: deepVehicle,
                                        profileName: this.nameify(deepProfiles[i]),
                                        noteExpand: false
                                    })
                                })
                            })
                        }
                        response.data.leReports.forEach(report => {
                            risc.getLeReport(report.leReportId).then(response => {
                                let deepLeReport = response.data;
                                const isUniqueReport = this.profileLeReports.every(function (leReport) {
                                    return leReport.id !== deepLeReport.leReportId
                                })
                                if (isUniqueReport) {
                                    this.profileLeReports.push({
                                        number: deepLeReport.leReportNumber,
                                        id: deepLeReport.leReportId,
                                        description: deepLeReport.description,
                                        involvement: deepLeReport.involvement,
                                        notes: deepLeReport.notes,
                                        documents: deepLeReport.documents,
                                        addresses: deepLeReport.addresses,
                                        profiles: [{
                                            name: this.nameify(deepProfiles[i]),
                                            id: deepProfiles[i].profileId
                                        }],
                                        noteExpand: false
                                    })
                                } else {
                                    this.profileLeReports.forEach(leReport => {
                                        if (leReport.id === report.leReportId) {
                                            leReport.profiles.push({
                                                address: deepLeReport,
                                                name: this.nameify(deepProfiles[i]),
                                                id: deepProfiles[i].profileId
                                            })
                                        }
                                    })
                                }
                                this.addressesToMarkers();
                            })
                        })
                        count10++
                        if (count10 === this.curIntelCase.profiles.length) {
                            this.curIntelCase.profiles = deepProfiles
                            this.addressesToMarkersBool++
                            if (this.addressesToMarkersBool === 2) {
                                this.addressesToMarkers()
                            }
                        }
                    })
                }
                if (!this.curIntelCase.profiles || this.curIntelCase.profiles.length === 0) {
                    this.addressesToMarkersBool++
                    if (this.addressesToMarkersBool === 2) {
                        this.addressesToMarkers()
                    }
                }
            },
            openEditModal() {
                risc.getProfiles().then(response => {
                    this.profiles.data = JSON.parse(JSON.stringify(response.data));
                    response.data.forEach((profile, index) => {
                        this.profiles.data[index] = {
                            ...profile,
                            ...{
                                name: this.nameify(profile),
                                DOB: this.dateify(profile.birthMonth, profile.birthDay, profile.birthYear),
                                alias: "",
                                id: profile.profileId
                            }
                        }
                        if (Array.isArray(profile.alias)) {
                            profile.alias.forEach((e, i) => {
                                this.profiles.data[index].alias += e.knownAlias + (profile.alias.length === i + 1 ? "" : ", ")
                            })
                        }
                    })
                })
                this.editCase.data = JSON.parse(JSON.stringify(this.curIntelCase));
                Object.keys(this.fields).forEach(field => {
                    if (!this.editCase.data[field] || this.editCase.data[field].length === 0) {
                        this.editCase.data[field] = [this.fields[field].format]
                    }
                })
                this.editCase.modal = true;
            },
            openModalAddVehicles() {
                this.profileObjects.vehicles.data = JSON.parse(JSON.stringify(this.profileObjects.vehicles.fields))
                this.getAllMakes();
                this.vehicleId = this.curIntelCase.vehicles.length + 1;
                this.profileObjects.vehicles.postPut = true;
            },
            openModalAddVehicleDocuments(id) {
                this.vdm = true
                this.$bvModal.show('doc')
                this.vehicleId = id
                this.vDocumentId = this.curIntelCase.vehicles[id].documents.length
            },
            openModalAddAddress() {
                this.profileObjects.addresses.data = {
                    active: false,
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: null,
                    notes: [{text: '', scope: this.intelCaseId}],
                    addressId: '',
                };
                this.addressId = this.curIntelCase.addresses.length + 1
                this.profileObjects.addresses.add = false;
                risc.getAddresses().then(response => {
                    if(!this.curIntelCase.addresses || this.curIntelCase.addresses.length === 0){
                        this.profileObjects.addresses.allAddresses = response.data
                        this.$forceUpdate()
                        this.profileObjects.addresses.postPut = true;
                    } else {
                        this.profileObjects.addresses.allAddresses = []
                        response.data.forEach((address, index) => {
                            let add = true
                            this.curIntelCase.addresses.forEach((oAddress, oIndex) => {
                                if (oAddress.addressId === address.addressId) {
                                    add = false
                                }
                                if (oIndex + 1 === this.curIntelCase.addresses.length) {
                                    if (add) this.profileObjects.addresses.allAddresses.push(address)
                                    if (index + 1 === response.data.length) {
                                        this.$forceUpdate()
                                        this.profileObjects.addresses.postPut = true;
                                    }
                                }
                            })
                        })
                    }
                })
                this.profileObjects.addresses.addAddressId = null;
            },
            openModalAddAddressDocuments(index) {
                this.addm = true
                this.$bvModal.show('doc')
                this.addressId = index
                this.addressDocumentId = this.curIntelCase.addresses[index].documents.length
            },
            openModalAddDocuments() {
                this.profileObjects.documents.data = {
                    description: '',
                    storageId: ''
                }
                this.documentId = this.curIntelCase.documents.length + 1
                this.$bvModal.show('doc')
                this.dm = true
            },
            openModalAddLeReport() {
                this.profileObjects.leReports.data = {
                    subject: '',
                    leReportNumber: '',
                    involvement: '',
                    date: undefined,
                    description: '',
                    notes: [{text: '', scope: this.intelCaseId}],
                    documents: [],
                    addresses: [],
                    leReportId: '',
                }
                this.profileObjects.leReports.add = false;
                risc.getLeReports().then(response => {
                    if(!this.curIntelCase.leReports || this.curIntelCase.leReports.length === 0){
                        this.profileObjects.leReports.all = response.data
                        this.$forceUpdate()
                        this.profileObjects.leReports.postPut = true;
                    } else {
                        this.profileObjects.leReports.all = []
                        response.data.forEach((leReport, index) => {
                            let add = true
                            this.curIntelCase.leReports.forEach((oLeReport, oIndex) => {
                                if (oLeReport.leReportId === leReport.leReportId) {
                                    add = false
                                }
                                if (oIndex + 1 === this.curIntelCase.leReports.length) {
                                    if (add) this.profileObjects.leReports.all.push(leReport)
                                    if (index + 1 === response.data.length) {
                                        this.$forceUpdate()
                                        this.profileObjects.leReports.postPut = true;
                                    }
                                }
                            })
                        })
                    }
                })
                this.profileObjects.leReports.addId = null;
            },
            openModalAddLeReportAddresses(report) {
                this.linkAddressBool = true;
                this.profileObjects.leReports.data.leReportId = report.leReportId;
                this.openModalAddAddress();
            },
            openModalAddLeReportDocuments(id) {
                this.ledm = true;
                this.$bvModal.show('doc');
                this.profileObjects.leReports.data.leReportId = id;
                this.leDocumentId = this.curIntelCase.leReports[id].documents.length;
            },
            openModalEditVehicles(data) {
                this.profileObjects.vehicles.data = Object.assign({}, JSON.parse(JSON.stringify(data)));
                this.vehicleMake = this.profileObjects.vehicles.data.make;
                this.getAllMakes();
                this.profileObjects.vehicles.postPut = true;
            },
            openModalEditAddresses(data) {
                this.profileObjects.addresses.data = Object.assign({}, JSON.parse(JSON.stringify(data)));
                this.profileObjects.addresses.addAddressId = null;
                this.profileObjects.addresses.postPut = true;
                this.profileObjects.addresses.add = true;
                this.addressInput = this.profileObjects.addresses.data.address1;
            },
            openModalEditAddressDocuments(item, index, documentIndex) {
                this.addressDocumentId = documentIndex
                this.profileObjects.addresses.data = JSON.parse(JSON.stringify(item))
                this.addressId = index
                this.$bvModal.show('edit-addoc')
            },
            openModalEditDocuments(item, index) {
                this.profileObjects.documents.data = JSON.parse(JSON.stringify(item))
                this.profileObjects.documents.postPut = true
                this.$bvModal.show('edit-doc')
                this.documentId = index
            },
            openModalEditVDocuments(item, index, dIndex) {
                this.vDocumentId = dIndex
                this.vehicleId = index
                this.profileObjects.vehicles.data = JSON.parse(JSON.stringify(item))
                this.$bvModal.show('edit-vdoc')
            },
            openModalEditLeReports(item) {
                this.profileObjects.leReports.data = JSON.parse(JSON.stringify(item))
                this.profileObjects.leReports.data.date = new Date(this.profileObjects.leReports.data.date)
                this.profileObjects.leReports.postPut = true;
                this.profileObjects.leReports.addId = null;
                this.profileObjects.leReports.add = true;
            },
            openModalEditLEDocuments(item, index, dIndex) {
                this.leDocumentId = dIndex
                this.profileObjects.leReports.data = JSON.parse(JSON.stringify(item))
                this.profileObjects.leReports.data.leReportId = index
                this.$bvModal.show('edit-ledoc')
            },
            deleteIntelCase() {
                risc.deleteCase(this.curIntelCase.intelCaseId).then(() => {
                    window.location = '/#/risc/intelCases'
                });
                this.$bvModal.hide("deleteIntelCase")
                this.deleteVerification = null;
            },
            openModalDeleteObject(name, id) {
                this.deleteObjects.modal = true
                this.deleteObjects.name = name
                this.deleteObjects.deleteText = ''
                this.deleteObjects.id = id
            },
            openModalDeleteVD(name, dId, vId) {
                this.deleteVD.modal = true
                this.deleteVD.name = name
                this.deleteVD.deleteText = ''
                this.deleteVD.dId = dId
                this.deleteVD.vId = vId
            },
            openViewModal(storageId) {
                this.fileViewStorageId = storageId
                this.$bvModal.show('file-view');
            },
            openModalFileSelector(files, field, index) {
                this.fileSelectorFiles = files;
                this.fieldToBeChanged = field;
                this.fieldToBeChangedIndex = index;
                this.docSelectBool = true;
                this.$bvModal.show('file-select');
            },
            postPutVehicle() {
                if (this.profileObjects.vehicles.data.vehicleId === '') {
                    delete this.profileObjects.vehicles.data.vehicleId
                    risc.postVehicle(this.profileObjects.vehicles.data).then(response => {
                        this.profileObjects.vehicles.data.vehicleId = response.data.vehicleId
                        risc.linkCaseVehicle(this.$route.params.id, this.profileObjects.vehicles.data.vehicleId).then(() => {
                            this.getIntelCase()
                        })
                    })
                } else {
                    let make = this.allMakes.find(_ => _.value === this.vehicleMake)
                    risc.putVehicle({
                        ...this.profileObjects.vehicles.data,
                        ...{make: (make ? make.text : this.vehicleMake), vin: this.vin}}).then(() => {
                        this.getIntelCase()
                    })
                }
                this.profileObjects.vehicles.postPut = false;
                this.vin = '';
                this.vehicleMake = '';
            },
            postPutVehicleDocument(description, fileId) {
                this.curIntelCase.vehicles[this.vehicleId].documents[this.vDocumentId] = {
                    description: description,
                    storageId: fileId,
                    arrayOrder: this.curIntelCase.vehicles[this.vehicleId].documents.length
                }
                risc.putVehicle(this.curIntelCase.vehicles[this.vehicleId]).then(() => {
                    this.getIntelCase()
                    this.vdm = false
                })
            },
            postPutAddress() {
                if (this.linkAddressBool === false) {
                    if (!this.profileObjects.addresses.add) {
                        risc.linkCaseAddress(this.$route.params.id, this.profileObjects.addresses.addAddressId).then(() => {
                            this.getIntelCase()
                        })
                    } else if (this.profileObjects.addresses.data.addressId === '') {
                        delete this.profileObjects.addresses.data.addressId
                        risc.postAddress(this.profileObjects.addresses.data).then(response => {
                            this.profileObjects.addresses.data.addressId = response.data.addressId
                            risc.linkCaseAddress(this.$route.params.id, this.profileObjects.addresses.data.addressId).then(() => {
                                this.getIntelCase()
                            })
                        })
                    } else {
                        risc.putAddress(this.profileObjects.addresses.data).then(() => {
                            this.getIntelCase()
                        })
                    }
                } else if (this.linkAddressBool === true) {
                    if(!this.profileObjects.addresses.add){
                        risc.linkAddressLeReport(this.profileObjects.addresses.addAddressId, this.profileObjects.leReports.data.leReportId).then(() => {
                            this.getIntelCase()
                        })
                    }else if (this.profileObjects.addresses.data.addressId === '') {
                        delete this.profileObjects.addresses.data.addressId
                        risc.postAddress(this.profileObjects.addresses.data).then(response => {
                            this.profileObjects.addresses.data.addressId = response.data.addressId
                            risc.linkAddressLeReport(this.profileObjects.addresses.data.addressId, this.profileObjects.leReports.data.leReportId).then(() => {
                                this.getIntelCase()
                            })
                        })
                    }
                }
                this.profileObjects.addresses.postPut = false;
                this.addressInput = '';
                this.linkAddressBool = false;
            },
            postPutAddressDocument(description, fileId) {
                this.curIntelCase.addresses[this.addressId].documents[this.addressDocumentId] = {
                    description: description,
                    storageId: fileId,
                    arrayOrder: this.curIntelCase.addresses[this.addressId].documents.length
                }
                risc.putAddress(this.curIntelCase.addresses[this.addressId]).then(() => {
                    this.getIntelCase()
                    this.addm = false
                })
            },
            postPutDocument(description, fileId) {
                this.curIntelCase.documents[this.documentId - 1] = {
                    description: description,
                    storageId: fileId,
                    arrayOrder: this.curIntelCase.documents.length
                }
                risc.putCase(this.curIntelCase).then(() => {
                    this.getIntelCase()
                    this.dm = false
                })
                this.profileObjects.documents.postPut = false
            },
            postPutLeReports() {
                if (this.profileObjects.leReports.data.date !== undefined) {
                    this.profileObjects.leReports.data.date = new Date(this.profileObjects.leReports.data.date);
                }
                if(!this.profileObjects.leReports.add){
                    risc.linkCaseLeReport(this.$route.params.id, this.profileObjects.leReports.addId).then(() => {
                        this.getIntelCase()
                    })
                } else if (this.profileObjects.leReports.data.leReportId === '') {
                    delete this.profileObjects.leReports.data.leReportId
                    risc.postLeReport(this.profileObjects.leReports.data).then(response => {
                        this.profileObjects.leReports.data.leReportId = response.data.leReportId
                        risc.linkCaseLeReport(this.$route.params.id, this.profileObjects.leReports.data.leReportId).then(() => {
                            this.getIntelCase()
                        })
                    })
                } else {
                    risc.putLeReport(this.profileObjects.leReports.data).then(() => {
                        this.getIntelCase();
                    })
                }
                this.profileObjects.leReports.postPut = false;
            },
            postPutLeReportDocument(description, fileId) {
                this.curIntelCase.leReports[this.profileObjects.leReports.data.leReportId].documents[this.leDocumentId] = {
                    description: description,
                    storageId: fileId,
                    arrayOrder: this.curIntelCase.leReports[this.profileObjects.leReports.data.leReportId].documents.length
                }
                risc.putLeReport(this.curIntelCase.leReports[this.profileObjects.leReports.data.leReportId]).then(() => {
                    this.getIntelCase()
                    this.ledm = false
                })
            },
            putNote() {
                this.curIntelCase.notes.push({
                    text: this.profileObjects.notes.newNote
                })
                risc.putCase(this.curIntelCase).then(() => {
                    this.getIntelCase()
                })
                this.profileObjects.notes.modal = false;
                this.profileObjects.notes.newNote = '';
            },
            encodeVehicleVIN() {
                axios
                    .get('https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/' + this.vin + '*BA?format=json')
                    .then(response => {
                        response.data.Results.forEach(result => {
                            if (result.Variable === 'Make') {
                                this.profileObjects.vehicles.data.make = result.Value;
                                this.vehicleMake = result.Value;
                            }
                            if (result.Variable === 'Model Year') {
                                this.profileObjects.vehicles.data.year = result.Value;
                            }
                            if (result.Variable === 'Model') {
                                this.profileObjects.vehicles.data.model = result.Value;
                            }
                        })
                    })
                    .catch(err => {
                        console.error(err);
                    })
            },
            getAllMakes() {
                this.allMakes = [];
                axios
                    .get('https://vpic.nhtsa.dot.gov/api/vehicles/GetAllMakes?format=json')
                    .then(response => {
                        this.allMakes = response.data.Results.map(i => ({
                            text: i.Make_Name,
                            value: i.Make_ID
                        }));
                    })
                    .catch(() => {
                        this.$mToast({
                            style: 'error',
                            title: 'Error Loading Vehicle API',
                            text: 'Vehicle API did not load correctly, contact Montel Support.'
                        });
                    })
            },
            getAllModels() {
                this.allMakes.forEach(make => {
                    if (make.text.toUpperCase() === this.vehicleMake) {
                        axios
                            .get('https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeId/' + make.value + '?format=json')
                            .then(response => {
                                this.allModels = response.data.Results.map(i => i.Model_Name);
                            })
                            .catch(() => {
                                this.$mToast({
                                    style: 'error',
                                    title: 'Error Loading Vehicle API',
                                    text: 'Vehicle API did not load correctly, contact Montel Support.'
                                });
                            })
                    }
                })
            },
            reverseNotesArray() {
                this.curIntelCase.notes = this.curIntelCase.notes.reverse()
            },
            getImage(storageId) {
                return risc.getImage(storageId);
            },
            getFirstDocumentImage(docArray) {
                let stId = 0;
                docArray.forEach(doc => {
                    if (doc.arrayOrder === 0) {
                        stId = doc.storageId;
                    }
                })
                if (stId !== 0) {
                    return risc.getImage(stId);
                } else {
                    return risc.getImage(docArray[0].storageId);
                }
            },
            changeDocumentDescription() {
                this.curIntelCase.documents.forEach(document => {
                    if (document.documentId === this.documentId) {
                        document.description = this.profileObjects.documents.data.description
                    }
                })
                risc.putCase(this.curIntelCase).then(() => {
                    this.getIntelCase()
                })
                this.$bvModal.hide('edit-doc')
            },
            changeVDDescription() {
                // this.curIntelCase.vehicles[this.vehicleId].documents[this.vDocumentId] = this.profileObjects.vehicles.data
                this.curIntelCase.vehicles[this.vehicleId].documents.forEach(document => {
                    if (document.documentId === this.vDocumentId) {
                        document.description = this.profileObjects.vehicles.data.description
                    }
                })
                risc.putVehicle(this.curIntelCase.vehicles[this.vehicleId]).then(() => {
                    this.getIntelCase()
                })
                this.$bvModal.hide('edit-vdoc')
            },
            changeAddressDDescription() {
                this.curIntelCase.addresses[this.addressId].documents.forEach(document => {
                    if (document.documentId === this.addressDocumentId) {
                        document.description = this.profileObjects.addresses.data.description
                    }
                })
                risc.putAddress(this.curIntelCase.addresses[this.addressId]).then(() => {
                    this.getIntelCase()
                })
                this.$bvModal.hide('edit-addoc')
            },
            changeLEDDescription() {
                this.curIntelCase.leReports[this.profileObjects.leReports.data.leReportId].documents.forEach(document => {
                    if (document.documentId === this.leDocumentId) {
                        document.description = this.profileObjects.leReports.data.description
                    }
                })
                risc.putLeReport(this.curIntelCase.leReports[this.profileObjects.leReports.data.leReportId]).then(() => {
                    this.getIntelCase()
                })
                this.$bvModal.hide('edit-ledoc')
            },
            changeNoteText(text, index) {
                this.noteText = text;
                this.profileObjects.notes.noteId = index;
                this.$bvModal.show('edit-note');
            },
            statusBadge(status) {
                return (status === "Open" ? "success" : (status === "On Hold" ? 'warning' : 'danger'))
            },
            nameify(profile) {
                if (profile.firstName && profile.lastName && profile.middleName) {
                    return (profile.lastName + ', ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
                } else if (profile.firstName && profile.lastName) {
                    return (profile.lastName + ', ' + profile.firstName)
                } else if (profile.firstName && profile.middleName) {
                    return ('Unknown, ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
                } else if (profile.lastName && profile.middleName) {
                    return (profile.lastName + ', Unknown ' + profile.middleName.slice(0, 1))
                } else if (profile.firstName) {
                    return ('Unknown, ' + profile.firstName)
                } else if (profile.lastName) {
                    return (profile.lastName + ', Unknown ')
                } else if (profile.middleName) {
                    return ('Unknown, Unknown ' + profile.middleName.slice(0, 1))
                }
            },
            dateify(month, day, year) {
                let result = "";
                result += (!month ? 'XX' : (month < 10 ? "0" + month : month)) + '/';
                result += (!day ? 'XX' : (day < 10 ? "0" + day : day)) + '/';
                result += (!year ? 'XXXX' : year);
                return result;
            },
            arrayify(start, end, addToEnd) {
                let result = [];
                for (let i = start; i < end + 1; i++) {
                    if (addToEnd) {
                        if (i > 9) {
                            result.push({text: i, value: i})
                        } else if (i >= 0) {
                            result.push({text: "0" + i, value: i})
                        }
                    } else {
                        if (i > 9) {
                            result.unshift({text: i, value: i})
                        } else if (i >= 0) {
                            result.unshift({text: "0" + i, value: i})
                        }
                    }
                }
                return (result);
            },
            unmarkAndMarkAll(selected, allMarked) {
                if (allMarked === false) {
                    let temp = [];
                    if (this.$refs.dTable.allFilteredData) {
                        this.$refs.dTable.allFilteredData.forEach(function (item) {
                            temp.push(item.profileId);
                        });
                    }
                    return (temp)
                } else {
                    return ([])
                }
            },
            associatesImage(images) {
                if (images && images.length >= 1) {
                    return (this.getImage(images[0].storageId));
                } else {
                    return ('/img/NoPictureOnFileGrey.png');
                }
            },
            findAddress(arr) {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].active) {
                        return (arr[i].address)
                    } else if (i === arr.length - 1) {
                        return (arr[i].address)
                    }
                }
            },
            filledOut(i) {
              return !(i === null || i === undefined || i === '' || i === 0);

            },
            oneFilledOut(obj,fields){
                let result = false
                let newObj = Object.assign({}, JSON.parse(JSON.stringify(obj)))
                Object.keys(fields).forEach((field,index) =>{
                    if(Array.isArray(fields[field]) && obj[field].length > 1){
                        let match = obj[field].every(ele => JSON.stringify(ele) === JSON.stringify(fields[field][0]))
                        newObj[field] = match ? [obj[field][0]] : false
                    }
                    if(index + 1 === Object.keys(fields).length){
                        result = JSON.stringify(newObj) === JSON.stringify(fields)
                    }
                })
                return result;
            },
            disableAddressSubmit() {
              return !this.filledOut(this.profileObjects.addresses.data.state) ||
                  !this.filledOut(this.profileObjects.addresses.data.city) ||
                  !this.filledOut(this.profileObjects.addresses.data.address1) ||
                  !this.filledOut(this.profileObjects.addresses.data.zip);

            },
            disableVehiclesSubmit() {
                return !this.filledOut(this.profileObjects.vehicles.data.make) ||
                    !this.filledOut(this.profileObjects.vehicles.data.model) ||
                    !this.filledOut(this.profileObjects.vehicles.data.year);

      },
            push(field, item) {
                field.push(JSON.parse(JSON.stringify(item)));
                this.refresh = false;
                this.refresh = true;
            },
            setFileFavorite(files) {
                let fieldToChange = this.fieldToBeChanged;
                let fieldToChangeIndex = this.fieldToBeChangedIndex;
                this.curIntelCase[fieldToChange][fieldToChangeIndex].documents = files
                if (fieldToChange === 'addresses') {
                    risc.putAddress(this.curIntelCase[fieldToChange][fieldToChangeIndex]).then(() => {
                        this.getIntelCase();
                    })
                } else if (fieldToChange === 'leReports') {
                    risc.putLeReport(this.curIntelCase[fieldToChange][fieldToChangeIndex]).then(() => {
                        this.getIntelCase();
                    })
                } else if (fieldToChange === 'vehicles') {
                    risc.putVehicle(this.curIntelCase[fieldToChange][fieldToChangeIndex]).then(() => {
                        this.getIntelCase();
                    })
                }
                this.$bvModal.hide('file-select');
                this.docSelectBool = false;
            },
            /*closeDocumentsModal() {
                this.profileObjects.documents.postPut = false
                this.profileObjects.documents.editModal = false
                this.profileObjects.documents.addModal = false
            },*/
            dateToString(date) {
                if (date === '0001-01-01T00:00:00') {
                    return '';
                } else {
                    return (moment(date).format('L'));
                }
            },
            ribbonVariant(ribbon) {
                switch (ribbon) {
                    case 'IN CUSTODY':
                        return 'success'
                    case 'PAROLE/PROBATION':
                        return 'warning text-dark'
                    case 'WANTED':
                        return 'danger'
                    case 'OUT OF TOWN':
                        return 'warning text-dark'
                    case 'DECEASED':
                        return 'dark';
                    default:
                        return 'primary';
                }
            },
            /*isRibbon() {
                return (this.profile.ribbon !== null && this.profile.ribbon !== 'NO RIBBON') ? true : false
            },*/
            disableLeReportSubmit() {
                return !this.filledOut(this.profileObjects.leReports.data.leReportNumber) ||
                    !this.filledOut(this.profileObjects.leReports.data.involvement) ||
                    !this.filledOut(this.profileObjects.leReports.data.description);

            },
            addressesToMarkers() {
                this.curIntelCase.addresses.forEach(address => {
                    if (!this.addressIdArray.includes(address.addressId)) {
                        this.addressIdArray.push(address.addressId);
                        let addressQuery = '';
                        if (address.address1 !== '') {
                            addressQuery = address.address1 + ', ' + address.city + ', ' + address.state + ' ' + address.zip;
                        } else {
                            addressQuery = address.city + ', ' + address.state + ' ' + address.zip;
                        }
                        fcr.getCoordinatesFromAddress(addressQuery).then(response => {
                            const marker = {
                                lat: response.data.body.results[0].geometry.location.lat,
                                lng: response.data.body.results[0].geometry.location.lng,
                                formatted_address: addressQuery
                            }
                            this.markers.push({position: marker})
                        })
                    }
                })
                this.curIntelCase.profiles.forEach(profile => {
                    profile.addresses.forEach(address => {
                        if (!this.addressIdArray.includes(address.addressId)) {
                            this.addressIdArray.push(address.addressId);
                            let addressQuery = '';
                            if (address.address1 !== '') {
                                addressQuery = address.address1 + ', ' + address.city + ', ' + address.state + ' ' + address.zip;
                            } else {
                                addressQuery = address.city + ', ' + address.state + ' ' + address.zip;
                            }
                            fcr.getCoordinatesFromAddress(addressQuery).then(response => {
                                const marker = {
                                    lat: response.data.body.results[0].geometry.location.lat,
                                    lng: response.data.body.results[0].geometry.location.lng,
                                    formatted_address: addressQuery
                                }
                                this.profileMarkers.push({position: marker});
                            })
                        }
                    })
                })
                this.curIntelCase.leReports.forEach(report => {
                    risc.getLeReport(report.leReportId).then(response => {
                        response.data.addresses.forEach(address => {
                            if (!this.addressIdArray.includes(address.addressId)) {
                                this.addressIdArray.push(address.addressId);
                                let addressQuery = '';
                                if (address.address1 !== '') {
                                    addressQuery = address.address1 + ', ' + address.city + ', ' + address.state + ' ' + address.zip;
                                } else {
                                    addressQuery = address.city + ', ' + address.state + ' ' + address.zip;
                                }
                                fcr.getCoordinatesFromAddress(addressQuery).then(response => {
                                    const marker = {
                                        lat: response.data.body.results[0].geometry.location.lat,
                                        lng: response.data.body.results[0].geometry.location.lng,
                                        formatted_address: addressQuery
                                    }
                                    this.leReportMarkers.push({position: marker});
                                })
                            }
                        })
                    })
                })
                this.profileLeReports.forEach(report => {
                    report.addresses.forEach(address => {
                        if (!this.addressIdArray.includes(address.addressId)) {
                            this.addressIdArray.push(address.addressId);
                            let addressQuery = '';
                            if (address.address1 !== '') {
                                addressQuery = address.address1 + ', ' + address.city + ', ' + address.state + ' ' + address.zip;
                            } else {
                                addressQuery = address.city + ', ' + address.state + ' ' + address.zip;
                            }
                            fcr.getCoordinatesFromAddress(addressQuery).then(response => {
                                const marker = {
                                    lat: response.data.body.results[0].geometry.location.lat,
                                    lng: response.data.body.results[0].geometry.location.lng,
                                    formatted_address: addressQuery
                                }
                                this.profileLeReportMarkers.push({position: marker});
                            })
                        }
                    })
                })
            },
            fitMapBounds() {
                this.$gmapApiPromiseLazy().then(() => {
                    // eslint-disable-next-line no-undef
                    this.bounds = new google.maps.LatLngBounds();
                    this.markers.forEach(marker => {
                        this.bounds.extend(marker.position);
                    })
                    this.profileMarkers.forEach(marker => {
                        this.bounds.extend(marker.position);
                    })
                    this.leReportMarkers.forEach(marker => {
                        this.bounds.extend(marker.position);
                    })
                    this.profileLeReportMarkers.forEach(marker => {
                        this.bounds.extend(marker.position);
                    })
                    this.$refs.myMap2.$mapObject.fitBounds(this.bounds);
                })
            },
            linkToGoogleMaps(marker) {
                window.open('https://www.google.com/maps/search/?api=1&query=' + marker.position.formatted_address);
            },
            toggleInfoWindow(marker) {
                this.markerWindow.position = { lat: marker.position.lat, lng: marker.position.lng };
                this.markerWindow.address = marker.position.formatted_address;
                this.markerWindow.open = true;
            },
            momentDate(date){
                return(moment(date).format('LLLL'))
            },
            /*toggleDropdown(index, aIndex) {
                let tempBool = !this.curIntelCase.profiles[index].noteExpand[aIndex]
                this.curIntelCase.profiles[index].noteExpand.splice(aIndex, 1, tempBool)
            },*/
            toggleDropdownProfileVehicles(index, vIndex) {
                let tempBool = !this.curIntelCase.profiles[index].noteExpandVehicles[vIndex]
                this.curIntelCase.profiles[index].noteExpandVehicles.splice(vIndex, 1, tempBool)
            },
            verifyThereAreNoNotes(notes) {
                const notesLengthEqualToZero = notes.every(note => {
                    return note.text.length === 0;
                })
                return notes.length === 0 || notesLengthEqualToZero;
            },
            async getAddressData(autocomplete) {
                this.$forceUpdate();
                const place = await autocomplete.getPlace();
                const ac = place.address_components;
                let streetNumber = '';
                let route = '';
                for (let i = 0; i < ac.length; i++) {
                    let addressType = ac[i].types[0];
                    if (addressType === 'street_number') {
                        streetNumber = ac[i].long_name.toUpperCase();
                    }
                    if (addressType === 'route') {
                        route = ac[i].long_name.toUpperCase();
                    }
                    if (addressType === 'locality') {
                        this.profileObjects.addresses.data.city = ac[i].long_name.toUpperCase();
                    }
                    if (addressType === 'administrative_area_level_1') {
                        this.profileObjects.addresses.data.state = ac[i].short_name.toUpperCase();
                    }
                    if (addressType === 'postal_code') {
                        this.profileObjects.addresses.data.zip = parseInt(ac[i].long_name);
                    }
                }
                this.addressInput = streetNumber + ' ' + route;
                this.profileObjects.addresses.data.address1 = streetNumber + ' ' + route;
            }
        },

        watch: {
            markers() {
                this.fitMapBounds();
            },
            profileMarkers() {
                this.fitMapBounds();
            },
            leReportMarkers() {
                this.fitMapBounds();
            },
            profileLeReportMarkers() {
                this.fitMapBounds();
            },
            addressInput() {
                const autocomplete = new this.google.maps.places.Autocomplete(
                    (document.getElementById('autocomplete')), { types: ['geocode'] }
                );
                autocomplete.setFields(['address_components']);
                autocomplete.addListener('place_changed', () => {
                    this.getAddressData(autocomplete);
                });
            },
            vehicleMake() {
                this.getAllModels();
            }
        }
    }

    /*Makes B-Form-Input All Caps*/
    Vue.directive('uppercase', {
        update (el) {
            el.value = el.value.toUpperCase()
        },
    })

</script>

<style scoped>
    .tab-scroll {
        min-height: 488px;
        border-width: 0;
        background-color: #1a1a1a !important;
    }
    .scroll {
        overflow-y: auto;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .img-center {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 0;
    }

    .img-center-ass{
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 0;
    }

    .image-parent{
        background-color: #000000 !important;
        flex-grow: 1;
        min-height: 0;
        display: flex;
        align-Items: center;
        justify-content: center;
    }

    .rectangle {
        height: 85px;
        width: 85px;
        text-align: center;
        line-height: 90px;
        vertical-align: middle
    }

    .square {
        width: 25px;
        text-align: center;
        line-height: 25px;
        vertical-align: middle
    }

    .tab-scroll {
        overflow-y: visible;
        min-height: 100%;
        background-color: #1a1a1a !important;
        border: none;
    }

    @media (min-width: 768px) {
        .row.equal {
            display: flex;
            flex-wrap: wrap;
        }
    }
</style>

<style>
    .tab-content {
        flex-grow: 1;
        border-bottom: 2px solid #23282c;
        overflow-y: auto;
    }

    .pac-container {
        z-index: 10000 !important;
    }
</style>