<template>

    <div style="margin: 5px 10px 5px 10px" v-if="text && text.length > 0">
        <div class="list-group-item p-0 clearfix">
            <div class="d-flex flex-row w-100">
                <div :style="'background-color:' + getColor()" class="d-flex flex-row">
                    <i class="icon-speech p-2 font-2xl float-left align-self-center"></i>
                </div>
                <div style="margin-left: 10px;">
                    <div class="h7 mb-0" style="color: #ffffff; margin-top: 3px; padding: 0;">
                        <p class="text-content" style="margin-right: 2px;">{{ text }}</p>
                    </div>
                    <div class="text-muted text-uppercase font-weight-bold" style="font-size: xx-small">
                        Created By: {{ notes[index].createdBy }}
                    </div>
                </div>
                <div class="ml-auto p-2; padding: 0">
                    <div style="width: 32px;">
                        <fa-icon :icon="['fas', 'pen-to-square']" @click="openModalEditNote(text, index, importance)" style="font-size: 0.8em; cursor: pointer;"/>
                        <fa-icon :icon="['fas', 'close']" @click="openModalDeleteObject('Note', notes[index].noteId)"
                            style="font-size: 0.8em; margin-left: 4px; cursor: pointer;"/>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 10px"/>
    </div>

</template>

<script>
export default {
    props: {
        text: { type: String },
        importance: { type: Number },
        index: { type: Number },
        notes: { type: Array },
        method1: { type: Function },
        method2: { type: Function },
    },
    model: {
        prop: 'text',
        event: 'textChange'
    },
    computed: {
        textLocal: {
            get: function() {
                return this.notes[this.index].text
            },
            set: function(value) {
                this.$emit('textChange', value)
            }
        }
    },
    data() {
        return {
            deleteNote: {
                modal: false,
                name: '',
                deleteText: '',
                id: null
            }
        }
    },
    methods: {
        openModalEditNote(text, index, importance) {
            if (this.isImportance(importance)) {
                this.method1(text, index, importance);
            } else {
                this.method1(text, index);
            }
        },
        isImportance(importance) {
            return importance === 0 || importance
        },
        openModalDeleteObject(name, id) {
            this.method2(name, id);
        },
        getColor() {
            switch (this.importance) {
                case 1:
                    return '#f64846';
                case -1:
                    return '#4dbd74';
                default:
                    return '#3283ba';
            }
        }
    }

}
</script>

<style>
    .text-content {
        min-width: 40%;
        word-break: break-word;
    }
</style>