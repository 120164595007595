<template>

    <!--Upload Document Modal-->
    <b-modal :id="id !== undefined ? id : 'doc'" no-close-on-backdrop scrollable title="Add Document/Media"
             class="modal-dark">

        <!--Document Description-->
        <b-input-group prepend="Description" class="input-group-sm" style="margin-bottom: 1px">
            <b-form-input v-model="descLocal"/>
        </b-input-group>

        <!--Input tag shows if upload data image length is 0-->
        <input v-if="upload.dataImageData.length === 0" type="file" id="files" ref="files"
               v-on:change="handleFileUpload()" accept="image/*" style="margin-top: 10px;"/>

        <!--Div showing image uploaded and ability to change the name-->
        <div v-else-if="upload.dataImageData.length !== 0" style="margin-top: 10px; margin-bottom: 10px;">
            <div v-for="(image,index) in upload.dataImageData" :key="index">
                <b-row>
                    <b-col md="3">
                        <img style="width: 110px; height: 70px; object-fit: contain"
                             :src="makeUrl(upload.dataImageData[index])" alt=""/>
                    </b-col>
                    <b-col md="9">
                        <span><b>Name for "{{ image.name }}":</b></span>
                        <b-input :placeholder="image.name" v-model="upload.dataImageName[index]"></b-input>
                    </b-col>
                </b-row>
                <br>
            </div>
        </div>

        <div @click="upload.dataImageName = []; upload.dataImageData = ''"
             style="display: inline-block; white-space: nowrap">
            <fa-icon :icon="['fas', 'backward']" size="lg" style="padding-left: 10px; cursor: pointer;"/>
            <span> Cancel File</span>
        </div>

        <!--Cancel and Submit Buttons-->
        <template slot="modal-footer">
            <b-button size="sm" variant="secondary" @click="closeUpload()">Cancel</b-button>
            <b-button size="sm" variant="primary" @click="uploadSubmit()" :disabled="upload.dataImageData.length === 0">
                Submit
            </b-button>
        </template>

    </b-modal>

</template>

<script>
import axios from 'axios'
import * as riscKeeper from '@/plugins/risckeeper-plugin'
import risc from '@/services/risc';

export default {
    props: {
        bool1: {type: Boolean},
        bool2: {type: Boolean},
        bool3: {type: Boolean},
        bool4: {type: Boolean},
        bool5: {type: Boolean},
        bool6: {type: Boolean},
        bool7: {type: Boolean},
        bool8: {type: Boolean},
        bool9: {type: Boolean},
        bool10: {type: Boolean},
        method1: {type: Function},
        method2: {type: Function},
        method3: {type: Function},
        method4: {type: Function},
        method5: {type: Function},
        method6: {type: Function},
        method7: {type: Function},
        method8: {type: Function},
        method9: {type: Function},
        method10: {type: Function},
        desc: {type: String},
        id: {type: String}
    },
    model: {
        prop: 'desc',
        event: 'descChange'
    },
    computed: {
        descLocal: {
            get: function () {
                return this.desc
            },
            set: function (value) {
                this.$emit('descChange', value)
            }
        }
    },
    data() {
        return {
            curImage: "",
            dataTable: {
                columns: ['name', 'image', 'edit'],
                options: {
                    orderBy: {column: 'order', ascending: true},
                    headings: {
                        name: 'Name',
                        preview: 'Edit',
                    },
                    sortable: ['name'],
                    filterable: ['name'],
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
                useVuex: false,
                theme: 'bootstrap4',
                template: 'default',
            },
            multiSelect: {
                toggle: false,
                allMarked: false,
                selected: [],
                areYouSure: false
            },
            edit: {
                modal: false,
                tempName: "",
                curImage: null,
                areYouSure: false,
            },
            images: [],
            token: localStorage.mtopToken,
            upload: {
                modal: false,
                dataImageData: '',
                dataImageName: [],
                tempInfo: [],
                count: 1,
                count2: 0,
                loadModal: false,
            },
            viewModal: false
        }
    },
    methods: {
        closeUpload() {
            // this.upload.modal = false;
            // this.upload.dataImageData = '';
            // this.upload.dataImageName = [];
            // this.upload.tempInfo = [];
            // this.upload.count = 1;
            // this.upload.count2 = 0;
            // this.description = ''
            this.descLocal = ''
            this.upload.dataImageName = []
            this.upload.dataImageData = ''
            this.$bvModal.hide(this.id !== undefined ? this.id : 'doc')
        },
        handleFileUpload() {
            this.upload.dataImageData = this.$refs.files.files;
        },
        makeUrl(file) {
            return (URL.createObjectURL(file))
        },
        uploadSubmit() {
            this.upload.modal = false
            for (let i = 0; i < this.upload.dataImageData.length; i++) {
                let formData = new FormData()
                // let fileName = this.upload.dataImageData[i].name
                let fileName = Math.floor(Math.random() * 1000000000) + this.upload.dataImageData[i].name.slice(this.upload.dataImageData[i].name.lastIndexOf("."), this.upload.dataImageData[i].name.length);
                formData.append('FileData', this.upload.dataImageData[i])
                formData.append('BlobName', "Blurb")
                formData.append('ContainerName', "Blurb")
                formData.append('FileName', fileName)
                riscKeeper.getAccessTokenAsync().then(token => {
                    axios.post(risc.get_risc_storage() + '/File/createfile', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(function (response) {
                        if (this.bool1) {
                            this.method1(this.descLocal, response.data.fileId)
                        }
                        if (this.bool2) {
                            this.method2(this.descLocal, response.data.fileId)
                        }
                        if (this.bool3) {
                            this.method3(this.descLocal, response.data.fileId)
                        }
                        if (this.bool4) {
                            this.method4(this.descLocal, response.data.fileId)
                        }
                        if (this.bool5) {
                            this.method5(this.descLocal, response.data.fileId)
                        }
                        if (this.bool6) {
                            this.method6(this.descLocal, response.data.fileId)
                        }
                        if (this.bool7) {
                            this.method7(this.descLocal, response.data.fileId)
                        }
                        if (this.bool8) {
                            this.method8(this.descLocal, response.data.fileId)
                        }
                        if (this.bool9) {
                            this.method9(this.descLocal, response.data.fileId)
                        }
                        if (this.bool10) {
                            this.method10(this.descLocal, response.data.fileId)
                        }
                        this.descLocal = ''
                    }.bind(this))
                });
            }
            this.$bvModal.hide(this.id !== null ? this.id : 'doc')
            this.upload.dataImageName = []
            this.upload.dataImageData = ''
        }
    }
}
</script>

<style scoped>

</style>