<template>

    <b-modal ok-only :id="id === null ? 'file-view' : id" title="Image Viewer" class="modal-dark" size="lg">
        <b-card style="padding-left: 0; overflow: hidden; position: relative;" class="align-center" no-body>
            <b-card-img style="object-fit: contain" v-auth-image="getImage(fileId)"/>
        </b-card>
    </b-modal>

</template>

<script>
    import risc from '@/services/risc.js';

    export default {
        props: {
            id: {type: String},
            fileId: { type: Number }
        },
        methods: {
            getImage(storageId) {
                return risc.getImage(storageId);
            }
        }
    }
</script>

<style>

</style>